import { IdType } from "@common/types/apiTypes";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { Subtitle1 } from "@ops-design-system/components/Typography/Typography";
import { EDIMessages } from "@ops/components/EDIMessages/EDIMessages";
import {
  StyledEdiMessageCardHeader,
  StyledEdiMessageCount,
} from "@ops/components/EdiMessagesCard/EdiMessagesCard.styled";
import { useEDIMessageHistoryQuery } from "@ops/hooks/queries/useEDIMessageHistory.query";
import React, { useEffect } from "react";

interface EdiMessagesCardProps {
  premiseId: IdType;
}
export const EdiMessagesCard = ({ premiseId }: EdiMessagesCardProps) => {
  const flash = useRhFlash();
  const ediMessageQuery = useEDIMessageHistoryQuery(premiseId);

  useEffect(() => {
    if (ediMessageQuery.error) {
      flash.error("Error fetching EDI messages");
    }
  }, [ediMessageQuery.error]);

  if (ediMessageQuery.isPending) {
    return <RhCircularProgress />;
  }

  if (ediMessageQuery.isError) {
    return (
      <BoCard>
        <StyledEdiMessageCardHeader>
          <Subtitle1 as="div">EDI Messages</Subtitle1>
          <StyledEdiMessageCount>
            There was an error fetching the EDI messages for this premise.
          </StyledEdiMessageCount>
        </StyledEdiMessageCardHeader>
      </BoCard>
    );
  }

  return (
    <BoCard>
      <StyledEdiMessageCardHeader>
        <Subtitle1 as="div">EDI Messages</Subtitle1>
        {ediMessageQuery.data.length > 0 && (
          <StyledEdiMessageCount>
            Showing {ediMessageQuery.data.length} of{" "}
            {ediMessageQuery.data.length}
          </StyledEdiMessageCount>
        )}
      </StyledEdiMessageCardHeader>
      <EDIMessages messages={ediMessageQuery.data} />
    </BoCard>
  );
};
