import { AppBrandNameMap, AppBrands } from "@common/enums/appBrands.enum";
import { stringOrEmDash } from "@common/utils/stringFormatters";
import {
  EnergyTexasTheme,
  RhythmTheme,
} from "@design-system/brandTheme/brandTheme";
import {
  BoTable,
  BoTableBody,
  BoTableCell,
  BoTableHead,
  BoTableHeadCell,
  BoTableRow,
} from "@ops-design-system/components/BoTable/BoTable";
import { Body2 } from "@ops-design-system/components/Typography/Typography";
import { FontWeight } from "@ops-design-system/styles/FontWeight.enum";
import { trueWhite } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { Prospect } from "@ops-utils/models/Prospect.model";
import { ProspectType } from "@ops-utils/types/prospectTypes";
import { prospectPath } from "@ops/routes/routePaths";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Header = styled(Body2).attrs({ as: "div" })`
  font-weight: ${FontWeight.SemiBold};
  margin-bottom: ${rhOpsSpacingPx(2)};
  margin-top: ${rhOpsSpacingPx(4)};
`;

const StyledProspectsTable = styled(BoTable)`
  width: 100%;
`;

interface ProspectRowProps {
  prospect: Prospect;
}

const ProspectRow = ({ prospect }: ProspectRowProps) => {
  const backgroundColor = () => {
    if (prospect.brand === AppBrands.ENERGY_TEXAS) {
      return EnergyTexasTheme.colors.primary.main;
    }

    if (prospect.brand === AppBrands.RHYTHM) {
      return RhythmTheme.colors.primary.main;
    }

    return "";
  };

  return (
    <BoTableRow aria-label={`Prospect - ${prospect.id}`}>
      <BoTableCell>
        <Link to={prospectPath(prospect.id)}>{prospect.id}</Link>
      </BoTableCell>
      <BoTableCell
        $align="center"
        $backgroundColor={backgroundColor()}
        $textColor={trueWhite}
      >
        {prospect.brand ? AppBrandNameMap[prospect.brand as AppBrands] : ""}
      </BoTableCell>
      <BoTableCell>{prospect.createdAtDate}</BoTableCell>
      <BoTableCell>{prospect.fullName}</BoTableCell>
      <BoTableCell>{prospect.email}</BoTableCell>
      <BoTableCell>{prospect.formattedPhoneNumber}</BoTableCell>
      <BoTableCell>{stringOrEmDash(prospect.esiId)}</BoTableCell>
      <BoTableCell>
        {!prospect.serviceAddress.isEmpty && prospect.serviceAddress.line1}
      </BoTableCell>
      <BoTableCell>{prospect.serviceAddress.city}</BoTableCell>
      <BoTableCell>{prospect.serviceAddress.state}</BoTableCell>
      <BoTableCell>{prospect.serviceAddress.zipCode}</BoTableCell>
    </BoTableRow>
  );
};

interface ProspectsTableProps {
  prospects: ProspectType[];
}

export const ProspectsTable: FC<
  React.PropsWithChildren<ProspectsTableProps>
> = ({ prospects }) => {
  return (
    <>
      <Header>{prospects.length} prospects found</Header>
      <StyledProspectsTable>
        <BoTableHead>
          <BoTableRow>
            <BoTableHeadCell>Prospect ID</BoTableHeadCell>
            <BoTableHeadCell>Brand</BoTableHeadCell>
            <BoTableHeadCell>Date</BoTableHeadCell>
            <BoTableHeadCell>Name</BoTableHeadCell>
            <BoTableHeadCell>Email</BoTableHeadCell>
            <BoTableHeadCell>Phone Number</BoTableHeadCell>
            <BoTableHeadCell>ESI-ID</BoTableHeadCell>
            <BoTableHeadCell>Service Address</BoTableHeadCell>
            <BoTableHeadCell>Service City</BoTableHeadCell>
            <BoTableHeadCell>State</BoTableHeadCell>
            <BoTableHeadCell>Zip Code</BoTableHeadCell>
          </BoTableRow>
        </BoTableHead>
        <BoTableBody>
          {prospects.map((prospect) => (
            <ProspectRow prospect={new Prospect(prospect)} key={prospect.id} />
          ))}
        </BoTableBody>
      </StyledProspectsTable>
    </>
  );
};
