import { AppBrands } from "@common/enums/appBrands.enum";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { CustomerTable } from "@ops/components/CustomerSearchTable/CustomerTable";
import { useCustomersSearchQuery } from "@ops/hooks/queries/useCustomersSearch.query";
import React from "react";

type CustomerSearchTableProps = {
  brand: AppBrands | "All";
  search: string;
};

export const CustomerSearchTable = (props: CustomerSearchTableProps) => {
  const flash = useRhFlash();
  const { brand, search } = props;

  const {
    data: customers,
    isLoading,
    error,
  } = useCustomersSearchQuery({
    queryOptions: {
      enabled: !!search,
    },
    searchOptions: {
      brand: brand === "All" ? undefined : brand,
      search,
    },
  });

  if (!search) {
    return null;
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    flash.error(error.statusText);

    return <div>The search request failed: {error.data.errorCode}</div>;
  }

  if (!customers || customers.length === 0) {
    return <div>Found no matches for &quot;{search}&quot;</div>;
  }

  return <CustomerTable customers={customers} />;
};
