import { EM_DASH } from "@common/constants/characters.constant";
import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { opsAndPricingIsRequired } from "@common/forms/validators";
import { formatPhoneNumber } from "@common/utils/dataFormatters";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import { BoSelectField } from "@ops-design-system/components/BoSelectField/BoSelectField";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { useAcquisitionCampaignsQuery } from "@ops-prospect-enroll/hooks/queries/useAcquisitionCampaigns.query";
import { ProspectType } from "@ops-utils/types/prospectTypes";
import { ProspectEditorForm } from "@ops/components/ProspectEditor/ProspectEditorForm";
import { TabType } from "@ops/components/ProspectEditorTabs/configuration";
import { useEnrollmentFlowContext } from "@ops/hooks/contexts/useEnrollmentFlowContext";
import React from "react";
import { Form } from "react-final-form";
import styled from "styled-components";

const marketingFormValidator =
  generateValidationErrorCollector<MarketingFormValues>({
    acquisitionCampaign: [opsAndPricingIsRequired],
  });

interface MarketingFormValues {
  acquisitionCampaign: string | null;
}

const StyledBoCard = styled(BoCard)`
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(4)};
`;

export const AcquisitionCampaignForm = () => {
  const { prospect, updateProspectState } = useEnrollmentFlowContext();
  const flash = useRhFlash();

  const acquisitionCampaignsQuery = useAcquisitionCampaignsQuery();

  if (acquisitionCampaignsQuery.isError) {
    flash.error("Could not retrieve list of Acquisition Campaigns");
    return null;
  }

  if (acquisitionCampaignsQuery.isPending) {
    return (
      <BoCard>
        <BoCircularProgress position="relative" />
      </BoCard>
    );
  }

  const acquisitionCampaigns = acquisitionCampaignsQuery.data;

  const activeCampaigns =
    acquisitionCampaigns.filter(({ active }) => active) ?? [];

  const existingSlug = prospect.acquisitionCampaign ?? "";
  const isExistingSlugInactive =
    existingSlug && !activeCampaigns.some(({ slug }) => slug === existingSlug);
  const inactiveCampaign = isExistingSlugInactive
    ? acquisitionCampaigns?.find(({ slug }) => slug === existingSlug)
    : null;

  const outsideSalesLabel =
    inactiveCampaign?.name && inactiveCampaign?.phone
      ? `${inactiveCampaign.name} ${formatPhoneNumber(inactiveCampaign.phone)} ${EM_DASH} Inactive, please choose an active campaign`
      : `${existingSlug} ${EM_DASH} Outside sales channel`;

  const campaignOptions = [
    ...(isExistingSlugInactive
      ? [{ label: outsideSalesLabel, value: existingSlug }]
      : []),
    ...(prospect.acquisitionMedium === "broker"
      ? [{ label: prospect.acquisitionMedium, value: existingSlug }]
      : []),
    ...activeCampaigns.map(({ name, phone, slug, brand }) => ({
      label: `${brand}: ${name} ${formatPhoneNumber(phone)}`,
      value: slug,
    })),
  ];

  const onSubmit = ({ acquisitionCampaign }: Partial<ProspectType>) => {
    const prospectPayload: Partial<ProspectType> = {
      acquisitionCampaign,
    };

    const targetAcquisitionCampaign = acquisitionCampaigns?.find(
      ({ slug }) => slug === acquisitionCampaign
    );

    if (prospect.acquisitionMedium !== "broker" && targetAcquisitionCampaign) {
      // Reset offer snapshot if the campaign has changed
      if (prospect.rcid !== targetAcquisitionCampaign.pricingCampaignSlug) {
        prospectPayload.offersnapshotId = null;
      }

      // reset offer snapshot if brand has changed
      if (prospect.brand !== targetAcquisitionCampaign.brand) {
        prospectPayload.offersnapshotId = null;
      }

      prospectPayload.rcid = targetAcquisitionCampaign.pricingCampaignSlug;

      prospectPayload.acquisitionMedium =
        targetAcquisitionCampaign.salesChannel;
      prospectPayload.acquisitionSource = targetAcquisitionCampaign.phone;
      prospectPayload.brand = targetAcquisitionCampaign.brand;
    }
    updateProspectState(prospectPayload);
  };

  return (
    <Form<MarketingFormValues>
      onSubmit={onSubmit}
      validate={marketingFormValidator}
      initialValues={{
        acquisitionCampaign: existingSlug,
      }}
      render={() => (
        <ProspectEditorForm id={TabType.marketing}>
          <StyledBoCard>
            <BoSelectField
              name="acquisitionCampaign"
              label="Campaign"
              options={campaignOptions}
            />
          </StyledBoCard>
        </ProspectEditorForm>
      )}
    />
  );
};
