import { ZuoraAddPaymentResponseType } from "@common/types/apiResponseTypes";
import { IdType } from "@common/types/apiTypes";
import { UpdatePaymentMethodType } from "@common/types/paymentMethodTypes";
import { zuora } from "@common/utils/zuora.util";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { useUpdateDefaultPaymentMethodMutation } from "@ops/hooks/mutations/useUpdateDefaultPaymentMethod.mutation";
import { useUpdatePaymentMethodMutation } from "@ops/hooks/mutations/useUpdatePaymentMethod.mutation";
import { useFetchRSASignatureQuery } from "@ops/hooks/queries/useFetchRSASignature.query";
import { getZuoraParams } from "@ops/utils/zuoraHelpers";
import debounce from "lodash/debounce";
import React, { useEffect, useState } from "react";

interface ZuoraAddBankAccountFormProps {
  onFailure?(error: string): void;
  onFormLoading?(isPending: boolean): void;
  onSuccess(zuoraResponse: ZuoraAddPaymentResponseType): void;
  premiseId: IdType | null;
  setAsDefault?: boolean;
}

export const ZuoraAddBankAccountForm = ({
  onSuccess,
  onFormLoading,
  onFailure,
  premiseId,
  setAsDefault,
}: ZuoraAddBankAccountFormProps) => {
  const flash = useRhFlash();
  const [isZuoraFormRendering, setIsZuoraFormRendering] =
    useState<boolean>(true);

  const zuoraParams = getZuoraParams(true);
  const fetchRSASignature = useFetchRSASignatureQuery(zuoraParams.id);
  const updatePaymentMethodMutation = useUpdatePaymentMethodMutation();
  const updateDefaultPaymentMethodMutation =
    useUpdateDefaultPaymentMethodMutation();

  const debouncedClientErrorMessageCallback = debounce(
    (errorType: string, errorCode: string, errorDescription: string) => {
      flash.error(errorDescription);
    },
    250
  );

  const handleUpdateDefaultPaymentMethod = (
    id: string,
    paymentMethodData: UpdatePaymentMethodType,
    onUpdateSuccess: () => void
  ) => {
    updateDefaultPaymentMethodMutation.mutate(
      { id, paymentMethodData },
      {
        onError: () => {
          onFailure?.(
            "Could not set this payment method as the default payment method."
          );
        },
        onSuccess: onUpdateSuccess,
      }
    );
  };

  const setZuoraFormLoading = (loading: boolean) => {
    setIsZuoraFormRendering(loading);
    onFormLoading?.(loading);
  };

  const handleZuoraResponse = (response: ZuoraAddPaymentResponseType) => {
    const errorMessage =
      "Unable to add bank account. Please verify your bank account information and try again.";

    if (!response.success) {
      onFailure?.(errorMessage);

      return Promise.resolve();
    }

    if (premiseId) {
      const paymentMethodData = { premiseId };

      updatePaymentMethodMutation.mutate(
        {
          id: response.refId,
          paymentMethodData,
        },
        {
          onError: () => {
            onFailure?.(errorMessage);
          },
          onSuccess: () => {
            if (!setAsDefault) {
              onSuccess(response);
              return;
            }

            handleUpdateDefaultPaymentMethod(
              response.refId,
              paymentMethodData,
              () => onSuccess(response)
            );
          },
        }
      );
    } else {
      onSuccess(response);
    }

    return Promise.resolve();
  };

  useEffect(() => {
    if (fetchRSASignature.isPending) {
      return;
    }

    zuora.setEventHandler("onloadCallback", () => {
      setZuoraFormLoading(false);
    });

    zuora.renderWithErrorHandler(
      {
        ...zuoraParams,
        ...fetchRSASignature.data,
      },
      {},
      handleZuoraResponse,
      debouncedClientErrorMessageCallback
    );
  }, [fetchRSASignature.isPending]);

  if (fetchRSASignature.isError) {
    onFailure?.(
      "We're having trouble connecting to the billing service. Please try again."
    );
    return null;
  }

  return (
    <>
      {isZuoraFormRendering && <RhCircularProgress marginBottom={4} />}
      <div id="zuora_payment" />
    </>
  );
};
