import { api } from "@common/api/api";
import { Customer } from "@common/models/Customer.model";
import { handleAjaxCall } from "@common/utils/handleAjaxCall";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoButtonWithConfirmDialog } from "@ops-design-system/components/BoButtonWithConfirmDialog/BoButtonWithConfirmDialog";
import { Subtitle2 } from "@ops-design-system/components/Typography/Typography";
import { StyledMessageContainer } from "@ops/components/SendResetPasswordButton/SendResetPasswordButton.styled";
import React from "react";

interface SendResetPasswordButtonProps {
  customer: Customer;
}

export const SendResetPasswordButton = ({
  customer,
}: SendResetPasswordButtonProps) => {
  const flash = useRhFlash();
  const handleConfirm = async () => {
    const [error] = await handleAjaxCall(
      api.customers.password.sendResetEmail({
        brand: customer.brand,
        email: customer.email,
      })
    );

    if (error) {
      flash.error(
        "Unable to send a reset password email for this customer. Please try again."
      );
    }
  };

  const message = (
    <StyledMessageContainer>
      <div>
        <Subtitle2>Send password reset email to:</Subtitle2>
      </div>
      <div>
        <Subtitle2 $fontWeight="Bold">{customer.email}</Subtitle2>
      </div>
    </StyledMessageContainer>
  );

  return (
    <BoButtonWithConfirmDialog
      handleConfirm={handleConfirm}
      header="Reset Password"
      confirmBtnText="Send"
      cancelBtnText="Cancel"
      buttonContent="Reset Password"
      message={message}
      size="extraSmall"
    />
  );
};
