import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const ProspectsPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(2)};
`;

export const StyledProspectPageTopContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledProspectPageCreateButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${rhOpsSpacingPx(1.5)};
`;
