import { acquisitionCampaignApi } from "@common/api/campaignPromosApi";
import { AcquisitionCampaignQueryKeys } from "@ops-data/constants/queryKeys.constants";
import { useQuery } from "@tanstack/react-query";

export const useAcquisitionCampaignsQuery = () => {
  return useQuery({
    queryFn: () => acquisitionCampaignApi.list(),
    queryKey: AcquisitionCampaignQueryKeys.list(),
  });
};
