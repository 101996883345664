import { IdType } from "@common/types/apiTypes";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import {
  BoGrid,
  BoGridColumn,
} from "@ops-design-system/components/BoGrid/BoGrid";
import { GridVariants } from "@ops-design-system/components/BoGrid/BoGrid.constants";
import { BillingPreferencesInfoCard } from "@ops/components/BillingPreferencesInfoCard/BillingPreferencesInfoCard";
import { ContactInfoCard } from "@ops/components/ContactInfoCard/ContactInfoCard";
import { PlanInfoCard } from "@ops/components/PlanInfo/PlanInfo";
import { PremiseAddressCard } from "@ops/components/PremiseAddressCard/PremiseAddressCard";
import { PremiseBillingCard } from "@ops/components/PremiseBillingCard/PremiseBillingCard";
import { useAccountSummaryQuery } from "@ops/hooks/queries/useAccountSummary.query";
import { useCustomer } from "@ops/hooks/useCustomer";
import { usePremise } from "@ops/hooks/usePremise";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

export const CustomerTab = () => {
  const flash = useRhFlash();
  const { id, premiseId } = useParams<{ id: IdType; premiseId: IdType }>();
  const { customer } = useCustomer(id);
  const { premise, error } = usePremise(premiseId);
  const {
    data: accountSummary,
    isError,
    isPending,
  } = useAccountSummaryQuery(premiseId);

  useEffect(() => {
    if (isError) {
      flash.error(
        "We're having trouble getting the account summary data. Please try again."
      );
    }
  }, [isError, flash]);

  if (error) {
    flash.error("Unable to fetch premise");
    return null;
  }

  if (!customer || !premise || !accountSummary || isPending) {
    return <BoCircularProgress />;
  }

  return (
    <BoGrid $variant={GridVariants.Grid1_1}>
      <BoGridColumn>
        <ContactInfoCard customer={customer} />
        <PremiseAddressCard />
      </BoGridColumn>
      <BoGridColumn>
        <PremiseBillingCard
          customer={customer}
          paymentExtension={premise.currentPaymentExtension}
          accountSummary={accountSummary}
        />
        <PlanInfoCard />
        <BillingPreferencesInfoCard
          pastDueBalance={accountSummary.pastDueBalance}
          premise={premise}
        />
      </BoGridColumn>
    </BoGrid>
  );
};
