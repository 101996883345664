import { grey } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const StyledCommercialServiceAddressTab = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(3)};
`;

export const StyledCommercialServiceAddressTabSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(1)};
`;

export const StyledSearchMeterForm = styled.div`
  border: 1px solid ${grey[100]};
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(3)};
  padding: ${rhOpsSpacingPx(2.5)} ${rhOpsSpacingPx(3)};
`;

export const StyledMetersSearchByRadiosContainer = styled.div`
  display: flex;
  gap: ${rhOpsSpacingPx(4)};
`;

export const StyledMetersSearchByRadio = styled.div`
  align-items: flex-end;
  display: flex;
  gap: ${rhOpsSpacingPx(1.5)};
`;

export const StyledSaveServiceAddressTabButtons = styled.div`
  display: flex;
  gap: ${rhOpsSpacingPx(2)};
`;
