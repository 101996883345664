import { rhapsodyProductsApi } from "@ops-data/api/rhapsodyProductsApi";
import { ProductKeys } from "@ops/constants/queryKeys.constants";
import { useQuery } from "@tanstack/react-query";

export const useProductsQuery = () => {
  return useQuery({
    queryFn: rhapsodyProductsApi.list,
    queryKey: ProductKeys.PRODUCTS,
  });
};
