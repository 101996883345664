import {
  BoHashTab,
  BoTabs,
} from "@ops-design-system/components/BoHashTab/BoHashTab";
import {
  CommercialProspectTabType,
  commercialEnrollmentTabs,
} from "@ops-prospect-enroll/components/CommercialProspectTabs/CommercialProspectTabs.constants";
import { StyledContainer } from "@ops-prospect-enroll/components/CommercialProspectTabs/CommercialProspectTabs.styled";
import React from "react";
import { useLocation } from "react-router-dom";

export const CommercialProspectTabs = () => {
  const location = useLocation();

  const hash = location.hash.startsWith("#")
    ? location.hash.slice(1)
    : location.hash;

  const activeTab = Object.values(CommercialProspectTabType).includes(
    hash as CommercialProspectTabType
  )
    ? (hash as CommercialProspectTabType)
    : CommercialProspectTabType.marketing;

  const tabComponent = commercialEnrollmentTabs.find(
    (tab) => tab.id === activeTab
  );

  return (
    <StyledContainer>
      <BoTabs>
        {commercialEnrollmentTabs.map((tab) => (
          <BoHashTab
            key={tab.id}
            hashkey={tab.id}
            name={tab.name}
            active={activeTab === tab.id}
          />
        ))}
      </BoTabs>
      {tabComponent?.component}
    </StyledContainer>
  );
};
