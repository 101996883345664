import { Premise } from "@common/models/Premise.model";
import { IdType } from "@common/types/apiTypes";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import { Body1 } from "@ops-design-system/components/Typography/Typography";
import { OfferSnapshot } from "@ops-utils/models/OfferSnapshot.model";
import { OfferSnapshotSearchOptions } from "@ops-utils/types/offerSnapshotTypes";
import { OfferCard } from "@ops/components/OfferCard/OfferCard";
import { OfferSnapshotCardsContainer } from "@ops/components/TransferServiceWizard/TransferOfServiceOfferSnapshotCards/TransferOfServiceOfferSnapshotCards.styled";
import { useOfferSnapshotsQuery } from "@ops/hooks/queries/useOfferSnapshots.query";
import React from "react";

interface TransferOfServiceOfferSnapshotCardsProps {
  onOfferSnapshotSelect: (offerSnapshot: OfferSnapshot) => void;
  premise: Premise;
  searchOptions: OfferSnapshotSearchOptions;
  selectedOfferSnapshotId?: IdType;
}

export const TransferOfServiceOfferSnapshotCards = (
  props: TransferOfServiceOfferSnapshotCardsProps
) => {
  const {
    premise,
    searchOptions,
    onOfferSnapshotSelect,
    selectedOfferSnapshotId,
  } = props;

  const offerSnapshotQuery = useOfferSnapshotsQuery({
    queryOptions: {
      enabled: Boolean(premise.currentOrder?.dunsNumber),
    },
    searchOptions: {
      brand: premise.brand,
      limit: 100,
      ...searchOptions,
    },
  });

  if (offerSnapshotQuery.isPending) {
    return (
      <div>
        <BoCircularProgress />
      </div>
    );
  }

  if (offerSnapshotQuery.isError) {
    return (
      <Body1>
        An error occurred. Unable to get offers for this service address.
      </Body1>
    );
  }

  if (offerSnapshotQuery.data.results.length === 0) {
    return (
      <Body1>
        No offers available for this service address. Please try again later.
      </Body1>
    );
  }

  const offerSnapshots = offerSnapshotQuery.data.results;

  return (
    <OfferSnapshotCardsContainer>
      {offerSnapshots.map((offerSnapshot) => (
        <OfferCard
          active={offerSnapshot.id === selectedOfferSnapshotId}
          key={offerSnapshot.id}
          offerSnapshot={offerSnapshot}
          onSelect={onOfferSnapshotSelect}
        />
      ))}
    </OfferSnapshotCardsContainer>
  );
};
