import { ajax } from "@common/services/ajax";
import { CreditScoreResponseType } from "@common/types/apiResponseTypes";
import { IdType, UUIDType } from "@common/types/apiTypes";
import { OfferSnapshotType } from "@ops-utils/types/offerSnapshotTypes";
import {
  ProspectCreditCheckRequestType,
  ProspectSearchRequestType,
  ProspectType,
} from "@ops-utils/types/prospectTypes";
import { ProspectValidationReportType } from "@ops-utils/types/prospectValidationReportTypes";

const BASE_PATH = "/api/rhapsody/prospects";

type ProspectCreatePostDataType = Partial<ProspectType>;

export const rhapsodyProspectApi = {
  availableOfferSnapshots: (prospectId: IdType) =>
    ajax.get<OfferSnapshotType[]>(
      `${BASE_PATH}/${prospectId}/available-offersnapshots`
    ),
  create: () => {
    return ajax.post<ProspectType>(`${BASE_PATH}/`, {});
  },
  creditScoreEvaluation: <R = CreditScoreResponseType>(
    customerData: ProspectCreditCheckRequestType
  ): Promise<R> => {
    return ajax.post<R, ProspectCreditCheckRequestType>(
      `${BASE_PATH}/credit_score_evaluation/`,
      customerData
    );
  },
  fraud: {
    premiseMatches: (
      prospectId: IdType
    ): Promise<ProspectValidationReportType[]> => {
      return ajax.get(`${BASE_PATH}/${prospectId}/premise-matches`);
    },
  },
  retrieve: <R = ProspectType>(prospectId: IdType | UUIDType): Promise<R> => {
    return ajax.get<R>(`${BASE_PATH}/${prospectId}/`);
  },

  search: (
    queryParams?: ProspectSearchRequestType
  ): Promise<ProspectType[]> => {
    return ajax.get<ProspectType[]>(`${BASE_PATH}/`, {
      params: queryParams,
    });
  },
  // TODO: refactor update to take a single object for eventual TanStack Query Mutation conversion
  update: <R = void>(
    prospectId: IdType,
    prospectData: ProspectCreatePostDataType
  ): Promise<R> => {
    return ajax.patch<R, ProspectCreatePostDataType>(
      `${BASE_PATH}/${prospectId}/`,
      prospectData
    );
  },
  validateReferralCode: ({
    prospectId,
    referralCode,
  }: {
    prospectId: IdType;
    referralCode: string;
  }) =>
    ajax.post<null>(`${BASE_PATH}/${prospectId}/validate-referral-code`, {
      referralCode,
    }),
};
