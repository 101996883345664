import { useFeatureFlagClient } from "@common/components/FeatureFlagClientProvider/useFeatureFlagClient";
import { AppBrands } from "@common/enums/appBrands.enum";
import {
  BoHashTab,
  BoTabs,
} from "@ops-design-system/components/BoHashTab/BoHashTab";
import {
  TabType,
  prospectEditorTabs,
  tabDisplayNames,
} from "@ops/components/ProspectEditorTabs/configuration";
import { useEnrollmentFlowContext } from "@ops/hooks/contexts/useEnrollmentFlowContext";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const ProspectEditorTabs = () => {
  const { featureFlagClient } = useFeatureFlagClient();
  const { hash } = useLocation();
  const { currentTabComponent, setCurrentTabComponent, prospect } =
    useEnrollmentFlowContext();

  const { opsProductAddOnsEnrollment } = featureFlagClient.getFlags([
    ["opsProductAddOnsEnrollment", false],
  ]);

  useEffect(() => {
    const hashTab = hash?.replace("#", "") as TabType;

    if (
      prospect.brand !== AppBrands.RHYTHM &&
      hashTab === TabType.productAddons
    ) {
      setCurrentTabComponent(TabType.marketing);
    }

    if (hashTab && prospectEditorTabs.includes(hashTab)) {
      setCurrentTabComponent(hashTab);
    }
  }, [hash, prospect.brand]);

  return (
    <BoTabs>
      {prospectEditorTabs.map((tabName) => {
        if (
          tabName === TabType.productAddons &&
          (!opsProductAddOnsEnrollment.value ||
            prospect.brand !== AppBrands.RHYTHM)
        ) {
          return null;
        }

        return (
          <BoHashTab
            key={tabName}
            hashkey={tabName}
            name={tabDisplayNames[tabName]}
            onClick={() => setCurrentTabComponent(tabName)}
            active={currentTabComponent === tabName}
          />
        );
      })}
    </BoTabs>
  );
};
