import { IdType } from "@common/types/apiTypes";
import { rhapsodyPremiseApi } from "@ops-data/api/rhapsodyPremiseApi";
import { OfferSnapshot } from "@ops-utils/models/OfferSnapshot.model";
import { PremiseKeys } from "@ops/constants/queryKeys.constants";
import { useQuery } from "@tanstack/react-query";

interface UsePremiseOneClickRenewalOfferSnapshotsProps {
  premiseId: IdType;
  retry?: boolean;
}

export const usePremiseOneClickRenewalOfferSnapshotsQuery = (
  props: UsePremiseOneClickRenewalOfferSnapshotsProps
) => {
  const { premiseId, retry = true } = props;

  return useQuery({
    enabled: Boolean(premiseId),
    queryFn: () => rhapsodyPremiseApi.oneClickRenewalOfferSnapshots(premiseId),
    queryKey: PremiseKeys.oneClickRenewalOfferSnapshots(premiseId),
    retry,
    select: (data) => {
      return {
        ...data,
        oneClickRenewalOfferSnapshots: data.oneClickRenewalOfferSnapshots.map(
          (oneClick) => {
            return {
              ...oneClick,
              offerSnapshot: new OfferSnapshot(oneClick.offerSnapshot),
            };
          }
        ),
      };
    },
  });
};
