import { useProductsQuery } from "@ops/hooks/queries/useProducts.query";
import { Product } from "@ops/models/Products/Product.model";
import { ProductPrice } from "@ops/models/Products/ProductPrice.model";

export const useProductPrices = () => {
  const productsQuery = useProductsQuery();

  if (!productsQuery.data) {
    // Return an empty array typed as ProductPrice[] to avoid having to check
    // the type of the data in the component (and to keep TS happy)
    return { ...productsQuery, data: [] as ProductPrice[] };
  }

  const productsList = productsQuery.data.results.map(
    (product) => new Product(product)
  );
  const productPricesList = productsList.reduce(
    (acc, product) => [...acc, ...product.prices],
    [] as ProductPrice[]
  );

  return {
    ...productsQuery,
    data: productPricesList,
  };
};
