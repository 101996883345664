import { ajax } from "@common/services/ajax";
import {
  SearchCommercialMetersRequestType,
  SearchCommercialMetersResponseType,
} from "@ops-utils/types/commercialMeterTypes";

const BASE_PATH = "/api/rhapsody/commercial-meters";

export const rhapsodyCommercialMetersApi = {
  search: (body: SearchCommercialMetersRequestType) =>
    ajax.post<SearchCommercialMetersResponseType>(`${BASE_PATH}/search`, body),
};
