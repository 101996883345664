import { ISO8601_DATE_FORMAT } from "@common/constants/date.constant";
import { IdType } from "@common/types/apiTypes";
import {
  formatCurrency,
  formatMonthDayYear,
} from "@common/utils/dataFormatters";
import { capitalizeFirstLetterOfWords } from "@common/utils/stringFormatters";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import {
  BoAccordion,
  BoAccordionDetails,
  BoAccordionSummary,
} from "@ops-design-system/components/BoAccordion/BoAccordion";
import {
  BoCard,
  BoCardLabel,
} from "@ops-design-system/components/BoCard/BoCard";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import {
  BoGrid,
  BoGridColumn,
} from "@ops-design-system/components/BoGrid/BoGrid";
import {
  BoInfoContainer,
  BoInfoLabel,
  BoInfoValue,
} from "@ops-design-system/components/BoInfo/BoInfo";
import { Body2 } from "@ops-design-system/components/Typography/Typography";
import { FontWeight } from "@ops-design-system/styles/FontWeight.enum";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { ProductContractCancelErrors } from "@ops-utils/types/errorTypes";
import { ProductContractType } from "@ops-utils/types/productContractTypes";
import { CancelProductContractDialog } from "@ops/components/CurrentProductContracts/CancelProductContractDialog";
import { ProductContractTosLink } from "@ops/components/ProductContractTosLink/ProductContractTosLink";
import { useCancelProductContractMutation } from "@ops/hooks/mutations/useCancelProductContract.mutation";
import { useActiveProductContractsQuery } from "@ops/hooks/queries/useActiveProductContracts.query";
import dayjs from "dayjs";
import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

// This is needed to allow the style targeting in `StyledBoAccordion`
const StyledBoAccordionSummary = styled(BoAccordionSummary)``;

const StyledBoAccordion = styled(BoAccordion)`
  & ${StyledBoAccordionSummary} ${Body2} {
    color: black;
    font-size: 14px;
    font-weight: ${FontWeight.Bold};
  }
`;

const StyledGrid = styled(BoGrid)`
  margin-bottom: ${rhOpsSpacingPx(2)};
`;

const StyledBoAccordionDetails = styled(BoAccordionDetails)`
  padding-bottom: ${rhOpsSpacingPx(3)};
`;

interface ProductContractDetailProps {
  premiseId: IdType;
  productContract: ProductContractType;
}
const ProductContractDetail = ({
  productContract,
  premiseId,
}: ProductContractDetailProps) => {
  const {
    id,
    productName,
    tierName,
    number,
    rate,
    startDate,
    endDate,
    termLength,
    termPeriod,
    tosUrl,
  } = productContract;
  const flash = useRhFlash();

  const cancelProductContractMutation =
    useCancelProductContractMutation(premiseId);

  const onConfirm = () => {
    // For now, we are going to manually set the end date to today until future functionality calls for choosing this
    const newEndDate = dayjs().format(ISO8601_DATE_FORMAT);

    cancelProductContractMutation.mutate(
      {
        contractId: id,
        endDate: newEndDate,
      },
      {
        onError: (error) => {
          // Since type is being declared, this is more readable than destructuring
          // eslint-disable-next-line prefer-destructuring
          const errorCode: ProductContractCancelErrors = error.data
            .errorCode as ProductContractCancelErrors;

          let flashError: string;

          switch (errorCode) {
            case ProductContractCancelErrors.PRODUCT_CONTRACT_ALREADY_ENDED: {
              flashError = "This Product Contract has already ended.";
              break;
            }
            case ProductContractCancelErrors.PRODUCT_CONTRACT_END_DATE_INVALID: {
              flashError = "An invalid end date was provided.";
              break;
            }
            case ProductContractCancelErrors.PRODUCT_CONTRACT_PENDING: {
              flashError =
                "The product contract is still pending and may not be canceled yet.";
              break;
            }
            default: {
              flashError = `Failed to cancel product contract: ${error?.data?.errorCode}`;
              break;
            }
          }

          flash.error(flashError);
        },
      }
    );
  };

  return (
    <StyledBoAccordion>
      <StyledBoAccordionSummary
        summary={`${productName} - ${tierName}`}
        label={`Open Product Contract - ${number}`}
      />
      <StyledBoAccordionDetails>
        <StyledGrid>
          <BoGridColumn>
            <BoInfoContainer>
              <BoInfoLabel>Monthly Cost</BoInfoLabel>
              <BoInfoValue>{formatCurrency(rate)}</BoInfoValue>
            </BoInfoContainer>

            <BoInfoContainer>
              <BoInfoLabel>Contract Start Date</BoInfoLabel>
              <BoInfoValue>{formatMonthDayYear(startDate)}</BoInfoValue>
            </BoInfoContainer>

            <BoInfoContainer>
              <BoInfoLabel>Policy Number</BoInfoLabel>
              <BoInfoValue>{number}</BoInfoValue>
            </BoInfoContainer>
          </BoGridColumn>
          <BoGridColumn>
            <BoInfoContainer>
              <BoInfoLabel>Term</BoInfoLabel>
              <BoInfoValue>
                {`${termLength} ${capitalizeFirstLetterOfWords(termPeriod)}`}
              </BoInfoValue>
            </BoInfoContainer>

            <BoInfoContainer>
              <BoInfoLabel>Contract End Date</BoInfoLabel>
              <BoInfoValue>{formatMonthDayYear(endDate)}</BoInfoValue>
            </BoInfoContainer>

            <BoInfoContainer>
              <BoInfoLabel>Terms and Conditions</BoInfoLabel>
              <BoInfoValue>
                <ProductContractTosLink tosUrl={tosUrl} />
              </BoInfoValue>
            </BoInfoContainer>
          </BoGridColumn>
        </StyledGrid>

        <CancelProductContractDialog
          productName={productName}
          onConfirm={onConfirm}
        />
      </StyledBoAccordionDetails>
    </StyledBoAccordion>
  );
};

export const CurrentProductContractsData = () => {
  const { premiseId } = useParams<{ premiseId: IdType }>();

  const query = useActiveProductContractsQuery(premiseId as IdType);

  if (query.isPending) {
    return <BoCircularProgress />;
  }

  if (query.isSuccess && query.data.count === 0) {
    return <Body2>No Current Customer Add Ons</Body2>;
  }

  if (query.isError) {
    return <Body2>Error Fetching Current Customer Add Ons</Body2>;
  }

  if (query.isSuccess) {
    return (
      <>
        {query.data.results.map((productContract: ProductContractType) => (
          <ProductContractDetail
            productContract={productContract}
            premiseId={premiseId}
            key={`Product Contract - ${productContract.number} - ${productContract.startDate}`}
          />
        ))}
      </>
    );
  }

  return null;
};

export const CurrentProductContracts = () => {
  return (
    <>
      <BoCardLabel>Current Customer Add Ons</BoCardLabel>
      <BoCard as="article">
        <CurrentProductContractsData />
      </BoCard>
    </>
  );
};
