import { IdType } from "@common/types/apiTypes";
import { formatMonthDayYear } from "@common/utils/dataFormatters";
import {
  BoAccordion,
  BoAccordionDetails,
} from "@ops-design-system/components/BoAccordion/BoAccordion";
import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import { Body2 } from "@ops-design-system/components/Typography/Typography";
import { yesNo } from "@ops-design-system/utils/booleanHelpers";
import { offersStepSchema } from "@ops/components/ContractRenewalWizard/ContractRenewalWizard.schemas";
import {
  EmailInfoContainer,
  EmailOfferSnapshotContainer,
  EmailOffersContainer,
  StyledBoAccordionSummary,
} from "@ops/components/ContractRenewalWizard/ContractRenewalWizardOffersStep/ContractRenewalWizardOffersStep.styled";
import { OfferCard } from "@ops/components/OfferCard/OfferCard";
import { usePremiseOneClickRenewalOfferSnapshotsQuery } from "@ops/hooks/queries/usePremiseOneClickRenewalOfferSnapshots.query";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { z } from "zod";

interface EmailOutReachOfferSnapshotsProps {
  premiseId: IdType;
  selectedOfferSnapshotId: IdType;
}

export const EmailOutReachOfferSnapshots = (
  props: EmailOutReachOfferSnapshotsProps
) => {
  const { premiseId, selectedOfferSnapshotId } = props;

  const { control, setValue } =
    useFormContext<z.infer<typeof offersStepSchema>>();

  const oneClickRenewalOfferSnapshotsQuery =
    usePremiseOneClickRenewalOfferSnapshotsQuery({ premiseId, retry: false });

  if (oneClickRenewalOfferSnapshotsQuery.isPending) {
    return <BoCircularProgress />;
  }

  if (oneClickRenewalOfferSnapshotsQuery.isError) {
    return (
      <BoCard>
        <Body2>Failed to fetch Email Outreach Offer Snapshots</Body2>
      </BoCard>
    );
  }

  const { oneClickRenewalOfferSnapshots } =
    oneClickRenewalOfferSnapshotsQuery.data;

  if (oneClickRenewalOfferSnapshots.length === 0) {
    return (
      <BoCard>
        <Body2>No Email Outreach Offer Snapshots</Body2>
      </BoCard>
    );
  }

  return (
    <BoAccordion>
      <StyledBoAccordionSummary summary="Email Outreach Offer Snapshots" />
      <BoAccordionDetails
        as="section"
        aria-label="Email Outreach Offer Snapshots"
      >
        <EmailOffersContainer>
          {oneClickRenewalOfferSnapshots.map((oneClick) => {
            const { offerSnapshot } = oneClick;

            const expiredId = `${offerSnapshot.id}-${oneClick.lastSegmentEventSent}-expired`;
            const dateOfferedId = `${offerSnapshot.id}-${oneClick.lastSegmentEventSent}-dateOffered`;

            return (
              <EmailOfferSnapshotContainer key={offerSnapshot.id}>
                <EmailInfoContainer>
                  <Body2 $fontWeight="Bold" id={expiredId}>
                    Expired
                  </Body2>
                  <Body2
                    data-expired={oneClick.expired}
                    aria-labelledby={expiredId}
                  >
                    {yesNo(oneClick.expired)}
                  </Body2>
                </EmailInfoContainer>
                <EmailInfoContainer>
                  <Body2 $fontWeight="Bold" id={dateOfferedId}>
                    Date Offered
                  </Body2>
                  <Body2 aria-labelledby={dateOfferedId}>
                    {formatMonthDayYear(oneClick.lastSegmentEventSent)}
                  </Body2>
                </EmailInfoContainer>
                <Controller
                  key={offerSnapshot.id}
                  control={control}
                  name="offerSnapshotId"
                  render={({ field }) => {
                    return (
                      <OfferCard
                        offerSnapshot={offerSnapshot}
                        active={offerSnapshot.id === selectedOfferSnapshotId}
                        onSelect={() => {
                          setValue("offerSnapshotId", offerSnapshot.id, {
                            shouldValidate: true,
                          });
                        }}
                      />
                    );
                  }}
                />
              </EmailOfferSnapshotContainer>
            );
          })}
        </EmailOffersContainer>
      </BoAccordionDetails>
    </BoAccordion>
  );
};
