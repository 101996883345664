import {
  formatCurrency,
  formatMonthDayYear,
  separateNumber,
} from "@common/utils/dataFormatters";
import { rewardPointsToDollars } from "@common/utils/rewardsHelpers";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import {
  BoTable,
  BoTableBody,
  BoTableCell,
  BoTableHead,
  BoTableHeadCell,
  BoTableRow,
} from "@ops-design-system/components/BoTable/BoTable";
import { H4 } from "@ops-design-system/components/Typography/Typography";
import { useAffiliateRedeemHistoryQuery } from "@ops/hooks/queries/useAffiliateRedeemHistory.query";
import React from "react";

interface RafRewardHistoryTableProps {
  affiliateId: string;
}

export const RafRewardHistoryTable = ({
  affiliateId,
}: RafRewardHistoryTableProps) => {
  const affiliateRedeemHistoryQuery = useAffiliateRedeemHistoryQuery({
    affiliateId,
  });

  if (affiliateRedeemHistoryQuery.isPending) {
    return <BoCircularProgress />;
  }

  if (affiliateRedeemHistoryQuery.isError) {
    return (
      <H4>
        There was an issue fetching the rewards redemption history for this
        affiliate. Please try again.
      </H4>
    );
  }

  return (
    <div>
      <H4>Reward Point Redemption History</H4>

      {affiliateRedeemHistoryQuery.data.length === 0 ? (
        <H4>This affiliate has not redeemed any reward points yet.</H4>
      ) : (
        <BoTable>
          <BoTableHead>
            <BoTableRow>
              <BoTableHeadCell>Date</BoTableHeadCell>
              <BoTableHeadCell>Points Redeemed</BoTableHeadCell>
              <BoTableHeadCell>Value</BoTableHeadCell>
            </BoTableRow>
          </BoTableHead>
          <BoTableBody>
            {affiliateRedeemHistoryQuery.data.map((rewardRedemptionHistory) => (
              <BoTableRow key={rewardRedemptionHistory.redeemedAt}>
                <BoTableCell>
                  {formatMonthDayYear(rewardRedemptionHistory.redeemedAt)}
                </BoTableCell>
                <BoTableCell>
                  {separateNumber(rewardRedemptionHistory.points)}
                </BoTableCell>
                <BoTableCell>
                  {formatCurrency(
                    rewardPointsToDollars(rewardRedemptionHistory.points)
                  )}
                </BoTableCell>
              </BoTableRow>
            ))}
          </BoTableBody>
        </BoTable>
      )}
    </div>
  );
};
