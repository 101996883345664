import { IdType } from "@common/types/apiTypes";
import { BoCardLabel } from "@ops-design-system/components/BoCard/BoCard";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import {
  StyledDevicesTab,
  StyledDevicesTabSection,
} from "@ops/components/CustomerDashboard/DevicesTab/DevicesTab.styled";
import { DeviceConnectionActivity } from "@ops/components/DeviceConnectionActivity/DeviceConnectionActivity";
import { DeviceDemandResponseActivity } from "@ops/components/DeviceDemandResponseActivity/DeviceDemandResponseActivity";
import { DeviceInformationCard } from "@ops/components/DeviceInformationCard/DeviceInformationCard";
import { DeviceSelector } from "@ops/components/DeviceSelector/DeviceSelector";
import { deviceSelectorAtom } from "@ops/components/DeviceSelector/DeviceSelector.atoms";
import { useAttachedDevicesQuery } from "@ops/hooks/queries/useAttachedDevices.query";
import { usePremise } from "@ops/hooks/usePremise";
import { useAtom } from "jotai";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

export const DevicesTab = () => {
  const { premiseId } = useParams<{ id: IdType; premiseId: IdType }>();
  const { premise } = usePremise(premiseId);
  const [selectedDevices, setSelectedDevices] = useAtom(deviceSelectorAtom);
  const {
    data: attachedDevicesData,
    isPending,
    isError,
  } = useAttachedDevicesQuery({
    premiseId,
    queryOptions: { enabled: Boolean(premise && premiseId) },
  });

  useEffect(() => {
    if (
      attachedDevicesData?.thermostats &&
      (!selectedDevices || (selectedDevices && !selectedDevices[premiseId]))
    ) {
      setSelectedDevices((prev) => ({
        ...prev,
        [premiseId]: attachedDevicesData.thermostats[0],
      }));
    }
  }, [attachedDevicesData, selectedDevices, setSelectedDevices, premiseId]);

  if (!premise || isPending) {
    return <BoCircularProgress />;
  }

  if (isError) {
    return <div>There was an error fetching the attached devices.</div>;
  }

  if (!attachedDevicesData?.thermostats.length) {
    return <div>No devices for this premise</div>;
  }

  if (!selectedDevices || !selectedDevices[premiseId]) {
    return null;
  }

  const customerDevices = "Customer Devices";
  const deviceInformation = "Device Information";
  const deviceConnectionActivity = "Device Connection Activity";
  const deviceDemandResponseActivity = "Device Demand Response Activity";

  return (
    <StyledDevicesTab>
      <StyledDevicesTabSection aria-label={customerDevices}>
        <BoCardLabel>{customerDevices}</BoCardLabel>
        <DeviceSelector
          devices={attachedDevicesData.thermostats}
          premiseId={premiseId}
        />
      </StyledDevicesTabSection>

      <StyledDevicesTabSection aria-label={deviceInformation}>
        <BoCardLabel>{deviceInformation}</BoCardLabel>
        <DeviceInformationCard device={selectedDevices[premiseId]} />
      </StyledDevicesTabSection>

      <StyledDevicesTabSection aria-label={deviceConnectionActivity}>
        <BoCardLabel>{deviceConnectionActivity}</BoCardLabel>
        <DeviceConnectionActivity
          premiseId={premiseId}
          thermostatId={selectedDevices[premiseId].smartThermostatId}
        />
      </StyledDevicesTabSection>

      <StyledDevicesTabSection aria-label={deviceDemandResponseActivity}>
        <BoCardLabel>{deviceDemandResponseActivity}</BoCardLabel>
        <DeviceDemandResponseActivity
          premiseId={premiseId}
          thermostatId={selectedDevices[premiseId].smartThermostatId}
        />
      </StyledDevicesTabSection>
    </StyledDevicesTab>
  );
};
