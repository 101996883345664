import { ReactComponent as SelectedOption } from "@design-system/icons/SelectedOption.svg";
import { ReactComponent as UnselectedOption } from "@design-system/icons/UnselectedOption.svg";
import { Body1 } from "@ops-design-system/components/Typography/Typography";
import { darkPurple, grey } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import React, { MouseEventHandler, useId } from "react";
import styled, { css } from "styled-components";

interface IconWrapperProps {
  disabled?: boolean;
}

const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
`;

interface StyledLabelTextProps {
  $htmlFor: string;
}

const StyledLabelText = styled(Body1).attrs<StyledLabelTextProps>(
  ({ $htmlFor }) => ({
    as: "label",
    htmlFor: $htmlFor,
  })
)<IconWrapperProps>`
  color: ${({ disabled }) => (disabled ? grey["300"] : "default")};
  cursor: pointer;
  margin-left: ${rhOpsSpacingPx(2)};
`;
const IconWrapper = styled.div<IconWrapperProps>`
  align-items: center;
  color: ${({ disabled }) => (disabled ? grey["300"] : darkPurple["500"])};
  display: flex;
  justify-content: center;
  position: relative;
`;

const StyledInput = styled.input`
  cursor: pointer;
  margin: 0;
  opacity: 0;
  position: absolute;
`;

export const BoRadioGroup = styled.div<{
  orientation?: "vertical" | "horizontal";
}>`
  ${({ orientation }) => {
    return orientation === "horizontal"
      ? css`
          align-items: center;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          & > * {
            margin-right: ${rhOpsSpacingPx(2)};
          }
          & > :last-child {
            margin-right: 0;
          }
        `
      : css`
          & > * {
            margin-bottom: ${rhOpsSpacingPx(2)};
          }
          & > :last-child {
            margin-bottom: 0;
          }
        `;
  }}
`;

interface BoRadioInputProps {
  checked?: boolean;
  defaultChecked?: boolean;
  disabled?: boolean;
  id?: string;
  label?: string;
  name?: string;
  onClick?: (val: number | string) => void;
  title?: string;
  value?: number | string;
}

export const BoRadioInput = ({
  label,
  title,
  checked,
  disabled,
  onClick,
  id,
  ...restProps
}: BoRadioInputProps) => {
  const defaultId = useId();
  let Icon = UnselectedOption;

  if (checked !== undefined) {
    Icon = checked ? SelectedOption : UnselectedOption;
  }

  const handleClick: MouseEventHandler<HTMLInputElement> = (ev) => {
    onClick && onClick(ev.currentTarget.value);
  };

  return (
    <Container>
      <IconWrapper disabled={disabled}>
        <Icon title={label ? undefined : title} />
      </IconWrapper>
      <StyledInput
        id={id ?? defaultId}
        type="radio"
        onClick={handleClick}
        {...restProps}
        title={title}
        disabled={disabled}
      />
      {label && (
        <StyledLabelText $htmlFor={id ?? defaultId} disabled={disabled}>
          {label}
        </StyledLabelText>
      )}
    </Container>
  );
};
