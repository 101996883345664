import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { BoTextButton } from "@ops-design-system/components/BoTextButton/BoTextButton";
import { OpsTextInput } from "@ops-design-system/components/OpsTextInput/OpsTextInput";
import { BoLabel } from "@ops-design-system/styles/common.styles";
import { useBrandsQuery } from "@ops-prospect-enroll/hooks/queries/useBrands.query";
import {
  FormButtonRow,
  SearchFormRow,
  SearchHeader,
  StyledOpsSelect,
} from "@ops/components/ProspectsSearchForm/ProspectsSearchForm.styled";
import React from "react";
import { useForm } from "react-hook-form";

export interface ProspectsSearchFormValues {
  addressLine1: string;
  brand: string;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  meterId: string;
  phone: string;
}

interface ProspectsSearchFormProps {
  onSubmit: (values: ProspectsSearchFormValues) => void;
}

export const ProspectsSearchForm = ({ onSubmit }: ProspectsSearchFormProps) => {
  const {
    handleSubmit,
    register,
    formState: { isDirty, isSubmitting },
    reset,
  } = useForm<ProspectsSearchFormValues>({
    defaultValues: {
      addressLine1: "",
      brand: "",
      email: "",
      firstName: "",
      id: "",
      lastName: "",
      meterId: "",
      phone: "",
    },
  });

  const brandsQuery = useBrandsQuery({});

  const handleFormSubmit = handleSubmit((data) => {
    onSubmit(data);
  });

  const brandOptions =
    brandsQuery.data?.map((brand) => {
      return {
        label: brand.nameShort,
        value: brand.slug,
      };
    }) ?? [];

  return (
    <>
      <SearchHeader>Search Prospects</SearchHeader>
      <BoCard>
        <form onSubmit={handleFormSubmit}>
          <SearchFormRow>
            <BoLabel>
              First Name
              <OpsTextInput {...register("firstName")} />
            </BoLabel>

            <BoLabel>
              Last Name
              <OpsTextInput {...register("lastName")} />
            </BoLabel>

            <BoLabel>
              Phone Number
              <OpsTextInput {...register("phone")} />
            </BoLabel>

            <BoLabel>
              Email
              <OpsTextInput {...register("email")} />
            </BoLabel>

            <BoLabel>
              Prospect ID
              <OpsTextInput {...register("id")} />
            </BoLabel>

            <BoLabel>
              Service Address
              <OpsTextInput {...register("addressLine1")} />
            </BoLabel>

            <BoLabel>
              Prospect ESI-ID
              <OpsTextInput {...register("meterId")} />
            </BoLabel>

            <BoLabel>
              Brand
              <StyledOpsSelect {...register("brand")}>
                <option value="">All Brands</option>
                {brandOptions.map((option) => {
                  return (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  );
                })}
              </StyledOpsSelect>
            </BoLabel>
          </SearchFormRow>

          <FormButtonRow>
            <BoButton type="submit" disabled={!isDirty} loading={isSubmitting}>
              Search
            </BoButton>
            <BoTextButton
              disabled={!isDirty}
              type="button"
              onClick={() => reset()}
            >
              Clear Search
            </BoTextButton>
          </FormButtonRow>
        </form>
      </BoCard>
    </>
  );
};
