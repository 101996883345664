import { IdType } from "@common/types/apiTypes";
import { pricingApi } from "@ops-data/api/pricingApi";
import { BoLink } from "@ops-design-system/components/BoLink/BoLink";
import { AXIOS_BASE_URL } from "@ops/settings/config";
import React, { MouseEventHandler, PropsWithChildren } from "react";

const baseDocumentLinkProps = {
  rel: "noopener noreferrer",
  target: "_blank",
};

interface DocumentLinkProps {
  onClick?: MouseEventHandler<HTMLAnchorElement> | undefined;
}

interface EFLLinkProps extends DocumentLinkProps {
  offerId: IdType;
}

interface TOSLinkProps extends DocumentLinkProps {
  tosUrl: string;
}

interface YRACLinkProps extends DocumentLinkProps {
  yracUrl: string;
}

export const EFLLink = ({
  offerId,
  onClick,
  children,
}: PropsWithChildren<EFLLinkProps>) => (
  <BoLink
    {...baseDocumentLinkProps}
    href={`${AXIOS_BASE_URL}${pricingApi.offerSnapshots.eflUrl(offerId)}`}
    onClick={onClick}
  >
    {children}
  </BoLink>
);

export const TOSLink = ({
  tosUrl,
  onClick,
  children,
}: PropsWithChildren<TOSLinkProps>) => (
  <BoLink {...baseDocumentLinkProps} href={tosUrl} onClick={onClick}>
    {children}
  </BoLink>
);

export const YRACLink = ({
  yracUrl,
  onClick,
  children,
}: PropsWithChildren<YRACLinkProps>) => (
  <BoLink {...baseDocumentLinkProps} href={yracUrl} onClick={onClick}>
    {children}
  </BoLink>
);
