import { grey } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const StyledCommercialMarketingTab = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(3)};
`;

export const StyledAcquisitionCampaignContainer = styled.div`
  border: 1px solid ${grey[100]};
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: ${rhOpsSpacingPx(2.5)} ${rhOpsSpacingPx(3)};
`;

export const StyledSaveMarketingTabButtons = styled.div`
  display: flex;
  gap: ${rhOpsSpacingPx(2)};
`;
