import { EnergyUsageGraphV2 } from "@common/components/EnergyUsageGraphV2/EnergyUsageGraphV2";
import { useLatestAvailableDate } from "@common/components/EnergyUsageGraphV2/useLatestAvailableDate";
import { useFeatureFlagClient } from "@common/components/FeatureFlagClientProvider/useFeatureFlagClient";
import { Premise } from "@common/models/Premise.model";
import {
  GraphTemperatureValue,
  SelectedGraph,
  SelectedGraphMode,
  chartTypeMapping,
} from "@common/types/usageTypes";
import { BoButtonTab } from "@ops-design-system/components/BoButtonTab/BoButtonTab";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import { BoDateInput } from "@ops-design-system/components/BoDate/BoDate";
import { OpsRadioInput } from "@ops-design-system/components/OpsRadioInput/OpsRadioInput";
import { H2 } from "@ops-design-system/components/Typography/Typography";
import { KwhUsageSummaryType } from "@ops-utils/types/usageTypes";
import { BillSummary } from "@ops/components/EnergyUsageChartV2/BillSummary/BillSummary";
import {
  DateAndTotalContainer,
  StyledBoButtonTabContainer,
  StyledNoEnergyDataYet,
  TemperaturesContainer,
} from "@ops/components/EnergyUsageChartV2/EnergyGraph/EnergyGraph.styled";
import { TotalUsage } from "@ops/components/EnergyUsageChartV2/TotalUsage/TotalUsage";
import { usePremiseKwhUsageDayQuery } from "@ops/hooks/queries/usePremiseKwhUsageDay.query";
import { usePremiseKwhUsageSummaryQuery } from "@ops/hooks/queries/usePremiseKwhUsageSummary.query";
import { usePremiseTemperatureSummaryQuery } from "@ops/hooks/queries/usePremiseTemperatureSummary.query";
import React, { useState } from "react";

interface EnergyGraphProps {
  premise: Premise;
}

// We don't need to map hourly data so exclude from this mapping
const usageDataMapping: Record<
  Exclude<SelectedGraph, "hourly">,
  keyof KwhUsageSummaryType
> = {
  [SelectedGraph.billPeriod]: "billPeriodData",
  [SelectedGraph.monthly]: "thirtyDaysData",
  [SelectedGraph.yearly]: "oneYearData",
};

const graphModeTabs: { label: string; value: SelectedGraphMode }[] = [
  { label: "Both", value: SelectedGraphMode.both },
  {
    label: "Consumption",
    value: SelectedGraphMode.consumption,
  },
  { label: "Surplus", value: SelectedGraphMode.surplus },
];

export const EnergyGraph = (props: EnergyGraphProps) => {
  const { premise } = props;

  const [selectedDate, setSelectedDate] = useState<string>("");

  const [selectedGraph, setSelectedGraph] = useState<SelectedGraph>(
    SelectedGraph.billPeriod
  );

  const [selectedGraphMode, setSelectedGraphMode] = useState<SelectedGraphMode>(
    SelectedGraphMode.both
  );
  const [temperature, setTemperature] = useState<
    GraphTemperatureValue | undefined
  >(GraphTemperatureValue.Average);

  const { featureFlagClient } = useFeatureFlagClient();
  const { portalTouGenerationUsageGraph, opsUsageGraphTemperatures } =
    featureFlagClient.getFlags([
      ["portalTouGenerationUsageGraph", false],
      ["opsUsageGraphTemperatures", false],
    ]);

  const kwhUsageSummaryQuery = usePremiseKwhUsageSummaryQuery({
    enabled: Boolean(
      premise.id && premise.currentOrder && premise.meter?.isSmt
    ),
    premiseId: premise.id,
  });

  const latestAvailableDate = useLatestAvailableDate(
    kwhUsageSummaryQuery.data?.thirtyDaysData || [],
    setSelectedDate
  );

  const kwhUsageDayQuery = usePremiseKwhUsageDayQuery({
    enabled: Boolean(
      premise.id &&
        premise.currentOrder &&
        premise.meter?.isSmt &&
        selectedDate !== ""
    ),
    premiseId: premise.id,
    targetDate: selectedDate,
  });

  const premiseTemperatureSummaryQuery = usePremiseTemperatureSummaryQuery({
    premiseId: premise.id,
    queryOptions: {
      enabled: Boolean(opsUsageGraphTemperatures.value),
    },
  });

  if (!premise.currentOrder) {
    return null;
  }

  if (
    kwhUsageSummaryQuery.isError ||
    kwhUsageDayQuery.isError ||
    (opsUsageGraphTemperatures.value && premiseTemperatureSummaryQuery.isError)
  ) {
    return <H2>Failed to get usage data</H2>;
  }

  if (
    kwhUsageSummaryQuery.isPending ||
    kwhUsageDayQuery.isPending ||
    (opsUsageGraphTemperatures.value &&
      premiseTemperatureSummaryQuery.isPending)
  ) {
    return <BoCircularProgress />;
  }

  const handleDateSelection = (dateString: string | null) => {
    if (dateString) {
      setSelectedDate(dateString);
    }
  };

  const tabs: { label: string; value: SelectedGraph }[] = [
    { label: "Current Billing Period", value: SelectedGraph.billPeriod },
    { label: "Hourly", value: SelectedGraph.hourly },
    { label: "Past 30 Days", value: SelectedGraph.monthly },
    { label: "Monthly", value: SelectedGraph.yearly },
  ];

  const usageData =
    selectedGraph === SelectedGraph.hourly
      ? kwhUsageDayQuery.data
      : kwhUsageSummaryQuery.data[usageDataMapping[selectedGraph]];

  const { solarEligible, solarGenerationCapped } = premise.currentOrder;

  const showGraphModeTabs =
    portalTouGenerationUsageGraph.value && solarEligible;

  const showGeneration = showGraphModeTabs
    ? selectedGraphMode === SelectedGraphMode.surplus ||
      selectedGraphMode === SelectedGraphMode.both
    : Boolean(solarEligible);

  const showConsumption =
    selectedGraphMode === SelectedGraphMode.consumption ||
    selectedGraphMode === SelectedGraphMode.both;

  const showTemperatures =
    opsUsageGraphTemperatures.value &&
    selectedGraph !== SelectedGraph.hourly &&
    (!showGraphModeTabs || selectedGraphMode !== SelectedGraphMode.both);

  return (
    <section>
      {showGraphModeTabs ? (
        <StyledBoButtonTabContainer>
          {graphModeTabs.map(({ label, value }) => (
            <BoButtonTab
              key={label}
              data-active={selectedGraphMode === value}
              onClick={() => {
                setSelectedGraphMode(value);
              }}
            >
              {label}
            </BoButtonTab>
          ))}
        </StyledBoButtonTabContainer>
      ) : null}
      <StyledBoButtonTabContainer>
        {tabs.map(({ label, value }) => (
          <BoButtonTab
            key={label}
            data-active={selectedGraph === value}
            onClick={() => {
              setSelectedGraph(value);
            }}
          >
            {label}
          </BoButtonTab>
        ))}
      </StyledBoButtonTabContainer>

      {showTemperatures ? (
        <TemperaturesContainer>
          <OpsRadioInput
            id="average-temp"
            checked={temperature === GraphTemperatureValue.Average}
            onChange={() => setTemperature(GraphTemperatureValue.Average)}
          />
          <label htmlFor="average-temp">Average Temperature</label>
          <OpsRadioInput
            id="average-temp"
            checked={temperature === GraphTemperatureValue.HighLow}
            onChange={() => setTemperature(GraphTemperatureValue.HighLow)}
          />
          <label htmlFor="average-temp">High/Low Temperature</label>
          <OpsRadioInput
            id="hide-temp"
            checked={temperature === undefined}
            onChange={() => setTemperature(undefined)}
          />
          <label htmlFor="average-temp">Hide Temperature</label>
        </TemperaturesContainer>
      ) : null}
      <DateAndTotalContainer>
        {selectedGraph === SelectedGraph.hourly ? (
          <BoDateInput
            label="Select Date"
            name="usageGraphDatePicker"
            minDate={premise.confirmedStartDate || undefined}
            maxDate={latestAvailableDate}
            value={selectedDate}
            onChange={handleDateSelection}
            aria-label="Select Date"
          />
        ) : null}

        <TotalUsage
          usageData={usageData}
          showConsumption={showConsumption}
          showGeneration={showGeneration}
          showEarned={!solarGenerationCapped}
        />
      </DateAndTotalContainer>

      {usageData.length === 0 ? (
        <StyledNoEnergyDataYet>
          No data for this period yet.
        </StyledNoEnergyDataYet>
      ) : (
        <EnergyUsageGraphV2
          usageData={usageData}
          chartType={chartTypeMapping[selectedGraph]}
          chartDirection="horizontal"
          showConsumption={showConsumption}
          showGeneration={showGeneration}
          showEarned={!solarGenerationCapped}
          latestAvailableDate={latestAvailableDate}
          selectedTemperatureValue={temperature}
          temperatures={
            showTemperatures && temperature
              ? premiseTemperatureSummaryQuery.data?.[
                  usageDataMapping[selectedGraph]
                ]
              : []
          }
        />
      )}

      <BillSummary premise={premise} />
    </section>
  );
};
