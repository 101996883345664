import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const OfferSnapshotCardsContainer = styled(BoCard)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${rhOpsSpacingPx(2)};
  justify-content: center;
`;
