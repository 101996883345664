import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import { CommercialProspectEnrollmentContext } from "@ops-prospect-enroll/components/CommercialProspectEnrollmentProvider/CommercialProspectEnrollmentProvider.context";
import { useCommercialProspectQuery } from "@ops-prospect-enroll/hooks/queries/useCommercialProspect.query";
import React from "react";

interface CommercialProspectProviderProps {
  children: React.ReactNode;
  id: string;
}

export const CommercialProspectProvider = ({
  children,
  id,
}: CommercialProspectProviderProps) => {
  const commercialProspectQuery = useCommercialProspectQuery({
    prospectId: id,
    queryOptions: { enabled: !!id },
  });
  const flash = useRhFlash();

  if (commercialProspectQuery.isPending) {
    return <BoCircularProgress />;
  }

  if (commercialProspectQuery.isError) {
    flash.error("Failed to get prospect data. Please try again.");

    return null;
  }

  return (
    <CommercialProspectEnrollmentContext.Provider
      value={commercialProspectQuery.data}
    >
      {children}
    </CommercialProspectEnrollmentContext.Provider>
  );
};
