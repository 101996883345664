import { CommercialMarketingTab } from "@ops-prospect-enroll/components/CommercialMarketingTab/CommercialMarketingTab";
import { CommercialServiceAddressTab } from "@ops-prospect-enroll/components/CommercialServiceAddressTab/CommercialServiceAddressTab";
import React from "react";

export enum CommercialProspectTabType {
  billing = "billing",
  confirmation = "confirmation",
  contactInfo = "contactInfo",
  marketing = "marketing",
  plan = "plan",
  serviceAddress = "serviceAddress",
}

type CommercialProspectTab = {
  component: React.ReactNode;
  id: CommercialProspectTabType;
  name: string;
};

export const commercialEnrollmentTabs: CommercialProspectTab[] = [
  {
    component: <CommercialMarketingTab />,
    id: CommercialProspectTabType.marketing,
    name: "Marketing",
  },
  {
    component: <CommercialServiceAddressTab />,
    id: CommercialProspectTabType.serviceAddress,
    name: "Service Address",
  },
  {
    component: <div>Plan Tab</div>,
    id: CommercialProspectTabType.plan,
    name: "Plan",
  },
  {
    component: <div>Contact Info Tab</div>,
    id: CommercialProspectTabType.contactInfo,
    name: "Contact Info",
  },
  {
    component: <div>Billing Tab</div>,
    id: CommercialProspectTabType.billing,
    name: "Billing",
  },
  {
    component: <div>Confirmation Tab</div>,
    id: CommercialProspectTabType.confirmation,
    name: "Confirmation",
  },
];
