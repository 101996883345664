import {
  ISO8601_DATE_FORMAT,
  MONTH_NAME_DAY_YEAR,
} from "@common/constants/date.constant";
import { OktaGroups } from "@common/constants/okta.constant";
import { Customer } from "@common/models/Customer.model";
import { formatPhoneNumber } from "@common/utils/dataFormatters";
import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { BoFlexBox } from "@ops-design-system/components/BoFlexBox/BoFlexBox";
import {
  BoGrid,
  BoGridColumn,
} from "@ops-design-system/components/BoGrid/BoGrid";
import {
  BoInfoContainer,
  BoInfoLabel,
  BoInfoValue,
} from "@ops-design-system/components/BoInfo/BoInfo";
import { BoLink } from "@ops-design-system/components/BoLink/BoLink";
import { languagePreferenceText } from "@ops-utils/types/LocaleTypes";
import { AccessControl } from "@ops/components/AccessControl/AccessControl";
import { StyledActionButtonContainer } from "@ops/components/ContactInfoCard/ContactInfoCard.styled";
import { EditCustomerName } from "@ops/components/ContactInfoCard/EditCustomerName/EditCustomerName";
import { EditCustomerNotifications } from "@ops/components/ContactInfoCard/EditCustomerNotifications/EditCustomerNotifications";
import { EditDateOfBirth } from "@ops/components/ContactInfoCard/EditDateOfBirth/EditDateOfBirth";
import { EditEmail } from "@ops/components/ContactInfoCard/EditEmail/EditEmail";
import { EditLanguagePreference } from "@ops/components/ContactInfoCard/EditLanguagePreference/EditLanguagePreference";
import { EditPhone } from "@ops/components/ContactInfoCard/EditPhone/EditPhone";
import { SendResetPasswordButton } from "@ops/components/SendResetPasswordButton/SendResetPasswordButton";
import { getIterableUrl } from "@ops/constants/url.constant";
import dayjs from "dayjs";
import React from "react";

interface ContactInfoCardProps {
  customer: Customer;
}

export const ContactInfoCard = ({ customer }: ContactInfoCardProps) => {
  const languagePreference = () =>
    `${languagePreferenceText[customer.languagePreference]}`;

  const dateOfBirthLongFormat = () =>
    `${dayjs(customer.dateOfBirth, ISO8601_DATE_FORMAT).format(
      MONTH_NAME_DAY_YEAR
    )}`;

  return (
    <BoCard data-testid="contact-info-card-target">
      <BoGrid>
        <BoGridColumn>
          <BoInfoContainer>
            <BoFlexBox $alignItems="center">
              <BoInfoLabel>Full Name</BoInfoLabel>
              <AccessControl allowedGroups={[OktaGroups.CSRTier3]}>
                <EditCustomerName customer={customer} />
              </AccessControl>
            </BoFlexBox>
            <BoInfoValue>{customer.fullName}</BoInfoValue>
          </BoInfoContainer>
          <BoInfoContainer>
            <BoInfoLabel>Date of Birth</BoInfoLabel>
            <AccessControl allowedGroups={[OktaGroups.CSRTier3]}>
              <EditDateOfBirth customer={customer} />
            </AccessControl>
            <BoInfoValue>{dateOfBirthLongFormat()}</BoInfoValue>
          </BoInfoContainer>
          <BoInfoContainer>
            <BoFlexBox $alignItems="center">
              <BoInfoLabel>Preferred Language</BoInfoLabel>
              <EditLanguagePreference customer={customer} />
            </BoFlexBox>
            <BoInfoValue>{languagePreference()}</BoInfoValue>
          </BoInfoContainer>
          <BoInfoContainer>
            <BoInfoLabel>Referral Code</BoInfoLabel>
            <BoInfoValue>{customer.referralToFriendCode}</BoInfoValue>
          </BoInfoContainer>
        </BoGridColumn>
        <BoGridColumn>
          <BoInfoContainer>
            <BoFlexBox $alignItems="center">
              <BoInfoLabel>Phone</BoInfoLabel>
              <EditPhone customer={customer} />
            </BoFlexBox>
            <BoInfoValue>
              Primary: {formatPhoneNumber(customer.phone)}
            </BoInfoValue>
            {customer.secondaryPhone && (
              <BoInfoValue>
                Secondary: {formatPhoneNumber(customer.secondaryPhone)}
              </BoInfoValue>
            )}
          </BoInfoContainer>
          <BoInfoContainer>
            <BoFlexBox $alignItems="center">
              <BoInfoLabel>Email</BoInfoLabel>
              <EditEmail customer={customer} />
            </BoFlexBox>
            <BoInfoValue>{customer.email}</BoInfoValue>
            <AccessControl>
              <BoLink
                href={getIterableUrl(
                  customer.email,
                  customer.brand,
                  customer.id,
                  customer.accountType
                )}
                target="__blank"
              >
                View in Iterable
              </BoLink>
            </AccessControl>
          </BoInfoContainer>
          <StyledActionButtonContainer>
            <SendResetPasswordButton customer={customer} />
            <EditCustomerNotifications />
          </StyledActionButtonContainer>
        </BoGridColumn>
      </BoGrid>
    </BoCard>
  );
};
