import { IdType } from "@common/types/apiTypes";
import {
  Body2,
  Body3,
  H3,
} from "@ops-design-system/components/Typography/Typography";
import { CommercialProspectProvider } from "@ops-prospect-enroll/components/CommercialProspectEnrollmentProvider/CommercialProspectEnrollmentProvider";
import { CommercialProspectTabs } from "@ops-prospect-enroll/components/CommercialProspectTabs/CommercialProspectTabs";
import { SupportLogs } from "@ops/components/SupportLogs/SupportLogs";
import { TargetType } from "@ops/components/SupportLogs/SupportLogs.types";
import {
  StyledCommercialProspectEnrollPage,
  StyledCommercialProspectEnrollPageTitle,
  StyledCommercialProspectEnrollPageTop,
  StyledProspectId,
  StyledSupportLogsArea,
} from "@ops/pages/CommercialProspectEnrollPage/CommercialProspectEnrollPage.styled";
import { prospectsHomePath } from "@ops/routes/routePaths";
import React from "react";
import { Link, useParams } from "react-router-dom";

export const CommercialProspectEnrollPage = () => {
  const { id } = useParams<{ id: IdType }>();

  return (
    <CommercialProspectProvider id={id}>
      <StyledCommercialProspectEnrollPage>
        <StyledCommercialProspectEnrollPageTop>
          <StyledCommercialProspectEnrollPageTitle>
            <Link to={prospectsHomePath()}>&lt; Prospects </Link>
            <H3>New Prospect - Commercial</H3>
          </StyledCommercialProspectEnrollPageTitle>
          <StyledProspectId>
            <Body3 $fontWeight="Bold">Prospect ID</Body3>
            <Body2>{id}</Body2>
          </StyledProspectId>
        </StyledCommercialProspectEnrollPageTop>
        <CommercialProspectTabs />
        <StyledSupportLogsArea>
          <SupportLogs target={{ id, type: TargetType.PROSPECT }} />
        </StyledSupportLogsArea>
      </StyledCommercialProspectEnrollPage>
    </CommercialProspectProvider>
  );
};
