import { PDF_MIME_TYPE } from "@common/constants/other.constant";
import { IdType } from "@common/types/apiTypes";
import { authenticatedFileDownload } from "@common/utils/authenticatedFileDownload";
import { formatMonthDayShortYear } from "@common/utils/dataFormatters";
import { rhapsodyProductContractClaimsApi } from "@ops-data/api/rhapsodyProductContractClaimsApi";
import {
  BoCard,
  BoCardLabel,
} from "@ops-design-system/components/BoCard/BoCard";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import {
  BoTable,
  BoTableBody,
  BoTableCaption,
  BoTableCell,
  BoTableHead,
  BoTableHeadCell,
  BoTableRow,
} from "@ops-design-system/components/BoTable/BoTable";
import { ReactComponent as DownloadIcon } from "@ops-design-system/icons/DownloadIcon.svg";
import { purple } from "@ops-design-system/styles/palette.colors";
import { ProductContractClaimType } from "@ops-utils/types/productContractTypes";
import { useProductContractClaimsQuery } from "@ops/hooks/queries/useProductContractClaims.query";
import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const Button = styled.button`
  all: unset;
  color: ${purple.dark};

  cursor: pointer;
`;

const TableHeadCell = styled(BoTableHeadCell)`
  white-space: nowrap;
`;

const handleDownload = (productContractClaim: ProductContractClaimType) => {
  authenticatedFileDownload(
    rhapsodyProductContractClaimsApi.downloadClaimUrl(productContractClaim.id),
    `${productContractClaim.policyNumber}-claim.pdf`,
    PDF_MIME_TYPE
  );
};

export const ProductContractClaimTable = () => {
  const { premiseId } = useParams<{ id: IdType; premiseId: IdType }>();

  const productContractClaimsQuery = useProductContractClaimsQuery(premiseId);

  if (productContractClaimsQuery.isPending) {
    return <BoCircularProgress />;
  }

  if (productContractClaimsQuery.isError) {
    return (
      <section>
        <BoCardLabel>Claims</BoCardLabel>
        <BoCard>Error fetching product contract claims</BoCard>
      </section>
    );
  }

  if (productContractClaimsQuery.data.length === 0) {
    return (
      <section>
        <BoCardLabel>Claims</BoCardLabel>
        <BoCard>No claims have been submitted</BoCard>
      </section>
    );
  }

  return (
    <BoTable>
      <BoTableCaption>Claims</BoTableCaption>
      <BoTableHead>
        <BoTableRow>
          <TableHeadCell>Name</TableHeadCell>
          <TableHeadCell>Policy Number</TableHeadCell>
          <TableHeadCell>Upload Date</TableHeadCell>
          <TableHeadCell>Claim Form</TableHeadCell>
        </BoTableRow>
      </BoTableHead>
      <BoTableBody>
        {productContractClaimsQuery.data.map((productContractClaim) => {
          return (
            <BoTableRow key={productContractClaim.id}>
              <BoTableCell>{productContractClaim.tierName}</BoTableCell>
              <BoTableCell>{productContractClaim.policyNumber}</BoTableCell>
              <BoTableCell>
                {formatMonthDayShortYear(productContractClaim.uploadDate)}
              </BoTableCell>
              <BoTableCell>
                <Button
                  type="button"
                  onClick={() => handleDownload(productContractClaim)}
                  aria-label="Download Claim"
                >
                  <DownloadIcon />
                </Button>
              </BoTableCell>
            </BoTableRow>
          );
        })}
      </BoTableBody>
    </BoTable>
  );
};
