import { IdType } from "@common/types/apiTypes";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import { DeviceConnectionActivityTable } from "@ops/components/DeviceConnectionActivityTable/DeviceConnectionActivityTable";
import { useThermostatConnectionActivityQuery } from "@ops/hooks/queries/useThermostatConnectionActivity.query";
import React, { useState } from "react";

interface DeviceConnectionActivityProps {
  premiseId: IdType;
  thermostatId: IdType;
}

export const DeviceConnectionActivity = (
  props: DeviceConnectionActivityProps
) => {
  const { premiseId, thermostatId } = props;
  // Controlled table state
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const {
    data: thermostatConnectionActivityData,
    error,
    isPending,
  } = useThermostatConnectionActivityQuery({
    premiseId,
    queryOptions: {
      enabled: Boolean(premiseId && thermostatId),
    },
    searchOptions: {
      limit: pageSize,
      offset: pageIndex * pageSize,
      thermostatId,
    },
  });

  if (error) {
    return <div>Failed to fetch device connection activity</div>;
  }

  if (isPending) {
    return <BoCircularProgress />;
  }

  if (!thermostatConnectionActivityData) {
    return null;
  }

  const pageCount = thermostatConnectionActivityData?.count
    ? Math.ceil(thermostatConnectionActivityData.count / pageSize)
    : 0;

  return (
    <DeviceConnectionActivityTable
      connectionActivity={thermostatConnectionActivityData.results}
      pageCount={pageCount}
      pageIndex={pageIndex}
      pageSize={pageSize}
      setPagination={setPagination}
    />
  );
};
