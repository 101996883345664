import { ISO8601_DATE_FORMAT } from "@common/constants/date.constant";
import { useModalState } from "@common/hooks/useModalState";
import { IdType, UUIDType } from "@common/types/apiTypes";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import {
  BoDialogBody,
  BoDialogHeader,
} from "@ops-design-system/components/BoDialog/BoDialogComponents";
import { BoFormWizard } from "@ops-design-system/components/BoFormWizard/BoFormWizard";
import { BoModal } from "@ops-design-system/components/BoModal/BoModal";
import { BoModalPositions } from "@ops-design-system/components/BoModal/BoModal.constants";
import { Subtitle2 } from "@ops-design-system/components/Typography/Typography";
import { ReactComponent as MapPinWithPlus } from "@ops-design-system/icons/MapPinWithPlus.svg";
import { ProductPriceTermPeriod } from "@ops-utils/types/productTypes";
import { ConfirmationPage } from "@ops/components/CreateProductContract/ConfirmationPage";
import { ProductSelectionPage } from "@ops/components/CreateProductContract/ProductSelectionPage";
import { useCreateProductContractMutation } from "@ops/hooks/mutations/useCreateProductContract.mutation";
import { useProductsQuery } from "@ops/hooks/queries/useProducts.query";
import { usePremise } from "@ops/hooks/usePremise";
import dayjs from "dayjs";
import React from "react";
import { useParams } from "react-router-dom";

export interface CreateProductContractFormValues {
  productSelection: UUIDType;
}

interface CreateProductContractFormProps {
  close: () => void;
}

const CreateProductContractForm = ({
  close,
}: CreateProductContractFormProps) => {
  const flash = useRhFlash();

  const { premiseId } = useParams<{ premiseId: IdType }>();

  const {
    premise,
    error: premiseError,
    requestMonitor: premiseRequestMonitor,
  } = usePremise(premiseId);

  const productQuery = useProductsQuery();

  const mutation = useCreateProductContractMutation(premiseId);

  if (productQuery.isPending || premiseRequestMonitor.isPending) {
    return <BoCircularProgress />;
  }

  if (premiseError || !premise) {
    return <Subtitle2>Could not retrieve data for premise.</Subtitle2>;
  }

  if (
    productQuery.isError ||
    (productQuery.isSuccess && productQuery.data.count === 0)
  ) {
    return <Subtitle2>No Add Ons Available</Subtitle2>;
  }

  const { currentOrder: order } = premise;

  if (!order) {
    return (
      <Subtitle2>
        Cannot Subscribe to Add Ons without an energy contract.
      </Subtitle2>
    );
  }

  const today = dayjs();
  const serviceStartDate = dayjs(order.startDate);
  const startDate = today > serviceStartDate ? today : serviceStartDate;

  const onSubmit = (values: CreateProductContractFormValues) => {
    mutation.mutate(
      {
        premiseId,
        priceId: values.productSelection,
        startDate: startDate.format(ISO8601_DATE_FORMAT),
        termLength: "12",
        termPeriod: ProductPriceTermPeriod.MONTHS,
      },
      {
        onError: (error) => {
          flash.error(
            `Failed to create Add On: ${error.data.errors[0].detail}`
          );
        },
        onSuccess: () => {
          flash.success("Add On successfully created");
          close();
        },
      }
    );
  };

  return (
    <BoFormWizard<CreateProductContractFormValues>
      onSubmit={onSubmit}
      formName="Create Product Contract From"
      handleCancel={close}
    >
      <ProductSelectionPage products={productQuery.data.results} />
      <ConfirmationPage startDate={startDate.toDate()} />
    </BoFormWizard>
  );
};

export const CreateProductContract = () => {
  const { open, close, isOpen } = useModalState();

  return (
    <>
      <BoButton
        icon={MapPinWithPlus}
        variant="secondary"
        size="extraSmall"
        onClick={open}
      >
        Add Ons
      </BoButton>
      {isOpen ? (
        <BoModal
          open
          onClose={close}
          size="large"
          position={BoModalPositions.Fixed}
        >
          <BoDialogHeader>New Add Ons</BoDialogHeader>
          <BoDialogBody>
            <CreateProductContractForm close={close} />
          </BoDialogBody>
        </BoModal>
      ) : null}
    </>
  );
};
