import { IdType } from "@common/types/apiTypes";
import { rhapsodyProspectApi } from "@ops-data/api/rhapsodyProspectApi";
import { ProspectQueryKeys } from "@ops-data/constants/queryKeys.constants";
import { OfferSnapshot } from "@ops-utils/models/OfferSnapshot.model";
import { useQuery } from "@tanstack/react-query";

interface ProspectAvailableOfferSnapshotsQueryOptions {
  enabled?: boolean;
  retry?: boolean;
}

interface ProspectAvailableOfferSnapshotsQueryProps {
  prospectId: IdType;
  queryOptions?: ProspectAvailableOfferSnapshotsQueryOptions;
}

export const useProspectAvailableOfferSnapshotsQuery = (
  props: ProspectAvailableOfferSnapshotsQueryProps
) => {
  const { queryOptions: { enabled = true, retry = true } = {}, prospectId } =
    props;

  return useQuery({
    enabled,
    queryFn: () => rhapsodyProspectApi.availableOfferSnapshots(prospectId),
    queryKey: ProspectQueryKeys.availableOfferSnapshots(prospectId),
    retry,
    select: (data) => data.map((snapshot) => new OfferSnapshot(snapshot)),
  });
};
