import { IdType } from "@common/types/apiTypes";
import { rhapsodyProspectApi } from "@ops-data/api/rhapsodyProspectApi";
import { ProspectQueryKeys } from "@ops/constants/queryKeys.constants";
import { useQuery } from "@tanstack/react-query";

interface UseProspectPremiseMatchesProps {
  enabled?: boolean;
  prospectId: IdType;
}

export const useProspectPremiseMatchesQuery = (
  props: UseProspectPremiseMatchesProps
) => {
  const { prospectId, enabled = true } = props;

  const query = useQuery({
    enabled,
    queryFn: () => rhapsodyProspectApi.fraud.premiseMatches(prospectId),
    queryKey: [...ProspectQueryKeys.PROSPECT_PREMISE_MATCHES, prospectId],
  });

  return query;
};
