import { EnrollmentType } from "@common/enums/customer.enum";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { BoSelectInput } from "@ops-design-system/components/BoSelectInput/BoSelectInput";
import { OpsCheckboxInput } from "@ops-design-system/components/OpsCheckboxInput/OpsCheckboxInput";
import {
  Body2,
  Body3,
} from "@ops-design-system/components/Typography/Typography";
import {
  StyledCommercialMeterColumn,
  StyledCommercialMeterPremiseTypeLabel,
  StyledCommercialMeterRow,
  StyledCommercialMeters,
  StyledMeterEnrollmentTypeContainer,
} from "@ops-prospect-enroll/components/CommercialMeters/CommercialMeters.styled";
import { DraftMeterEnrollmentType } from "@ops-utils/types/commercialMeterTypes";
import React, { Dispatch, SetStateAction } from "react";

interface CommercialMetersProps {
  draftMeters: DraftMeterEnrollmentType[];
  meterError: boolean;
  setDraftMeters: Dispatch<SetStateAction<DraftMeterEnrollmentType[]>>;
}
export const CommercialMeters = ({
  draftMeters,
  meterError,
  setDraftMeters,
}: CommercialMetersProps) => {
  return (
    <StyledCommercialMeters aria-invalid={meterError}>
      {draftMeters.map((meter) => (
        <StyledCommercialMeterRow key={meter.esiId}>
          <StyledCommercialMeterColumn>
            <OpsCheckboxInput
              checked={meter.checked}
              onChange={(event) => {
                setDraftMeters(
                  draftMeters.map((draftM) => {
                    if (draftM.esiId === meter.esiId) {
                      return {
                        ...meter,
                        checked: event.target.checked,
                      };
                    }
                    return draftM;
                  })
                );
              }}
            />
          </StyledCommercialMeterColumn>
          <StyledCommercialMeterColumn>
            <Body2 $fontWeight="Bold">Service Address</Body2>
            <Body3>{meter.addressLine}</Body3>
            <Body3>{meter.unitNumber}</Body3>
            <Body3>{`${meter.city},${meter.state} ${meter.zipCode}`}</Body3>
          </StyledCommercialMeterColumn>
          <StyledCommercialMeterColumn>
            <Body2 $fontWeight="Bold">ESI-ID</Body2>
            <Body3>{meter.esiId}</Body3>
          </StyledCommercialMeterColumn>
          <StyledCommercialMeterColumn>
            <Body2 $fontWeight="Bold">POLR Class</Body2>
            <Body3>{meter.polrCustomerClass}</Body3>
            <StyledCommercialMeterPremiseTypeLabel $fontWeight="Bold">
              Premise Type
            </StyledCommercialMeterPremiseTypeLabel>
            <Body3>{meter.premiseType}</Body3>
          </StyledCommercialMeterColumn>
          <StyledCommercialMeterColumn>
            <Body2 $fontWeight="Bold">Utility</Body2>
            <Body3>{meter.utility}</Body3>
          </StyledCommercialMeterColumn>
          <StyledCommercialMeterColumn>
            <StyledMeterEnrollmentTypeContainer
              aria-invalid={meter.enrollmentType === undefined}
            >
              <Body2 $fontWeight="Bold" as="label" htmlFor="enrollment-type">
                Enrollment Type
              </Body2>
              <BoSelectInput
                id="enrollment-type"
                hasErrors={meter.enrollmentType === undefined}
                placeholder="Choose"
                value={meter.enrollmentType}
                onChange={(option) => {
                  if (option && option.value) {
                    setDraftMeters(
                      draftMeters.map((draftM) => {
                        if (draftM.esiId === meter.esiId) {
                          return {
                            ...meter,
                            enrollmentType: option.value as EnrollmentType,
                          };
                        }
                        return draftM;
                      })
                    );
                  }
                }}
                options={[
                  { label: "Move in", value: EnrollmentType.MOVE_IN },
                  { label: "Switch", value: EnrollmentType.SWITCH },
                ]}
              />
              {meter.enrollmentType === undefined ? (
                <Body3>Please choose</Body3>
              ) : null}
            </StyledMeterEnrollmentTypeContainer>
          </StyledCommercialMeterColumn>
          <StyledCommercialMeterColumn>
            <BoButton
              size="extraSmall"
              variant="error"
              onClick={() =>
                setDraftMeters(
                  draftMeters.filter((m) => m.esiId !== meter.esiId)
                )
              }
            >
              Remove
            </BoButton>
          </StyledCommercialMeterColumn>
        </StyledCommercialMeterRow>
      ))}
    </StyledCommercialMeters>
  );
};
