import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { OpsTextInput } from "@ops-design-system/components/OpsTextInput/OpsTextInput";
import styled from "styled-components";

export const StyledCommercialSearchEsiidForm = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const StyledEsiidInput = styled(OpsTextInput)`
  width: 400px;
`;

export const StyledValidateButton = styled(BoButton)`
  margin-top: 110px;
  width: 150px;
`;
