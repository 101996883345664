import { EM_DASH } from "@common/constants/characters.constant";
import { formatDollarsToCents } from "@common/utils/dataFormatters";
import { stringOrEmDash } from "@common/utils/stringFormatters";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import {
  BoGrid,
  BoGridColumn,
} from "@ops-design-system/components/BoGrid/BoGrid";
import { GridVariants } from "@ops-design-system/components/BoGrid/BoGrid.constants";
import {
  BoInfoContainer,
  BoInfoLabel,
  BoInfoValue,
} from "@ops-design-system/components/BoInfo/BoInfo";
import { Body2 } from "@ops-design-system/components/Typography/Typography";
import {
  EFLLink,
  TOSLink,
  YRACLink,
} from "@ops/components/LegalDocumentLinks/LegalDocumentLinks";
import { ConfirmationSection } from "@ops/components/ProspectEditor/ConfirmationTab/ConfirmationSection";
import { ConfirmationCardType } from "@ops/components/ProspectEditor/ConfirmationTab/prospectConfirmationTypes";
import { TOUEnergyBreakdownModals } from "@ops/components/TOUEnergyBreakdownModals/TOUEnergyBreakdownModals";
import { formatEnergyRate } from "@ops/formatters";
import { useOfferSnapshotQuery } from "@ops/hooks/queries/useOfferSnapshot.query";
import React from "react";
import styled from "styled-components";

const DocumentEntry = styled.div`
  & > a {
    font-size: 10px;
    margin-bottom: 4px;
  }
`;

interface ConfirmationPlanCardProps extends ConfirmationCardType {}

export const ConfirmationPlanCard = ({
  prospect,
  errors,
}: ConfirmationPlanCardProps) => {
  const offerSnapshotQuery = useOfferSnapshotQuery({
    offerSnapshotId: prospect.offersnapshotId ?? "",
    queryOptions: {
      enabled: Boolean(prospect.offersnapshotId),
    },
  });

  if (!prospect.offersnapshotId) {
    return (
      <ConfirmationSection label="Plan" error>
        <BoGrid $variant={GridVariants.Grid2_1}>
          <BoGridColumn>
            <Body2>No Plan Selected</Body2>
          </BoGridColumn>
          <BoGridColumn>
            <BoInfoContainer>
              <BoInfoLabel>Referral Code</BoInfoLabel>
              <BoInfoValue>{stringOrEmDash(prospect.referralCode)}</BoInfoValue>
            </BoInfoContainer>
          </BoGridColumn>
        </BoGrid>
      </ConfirmationSection>
    );
  }

  if (offerSnapshotQuery.isPending) {
    return (
      <ConfirmationSection label="Plan">
        <BoCircularProgress />
      </ConfirmationSection>
    );
  }

  if (offerSnapshotQuery.isError) {
    return (
      <ConfirmationSection label="Plan" error>
        <Body2>Failed to get plan information</Body2>
      </ConfirmationSection>
    );
  }

  const offerSnapshot = offerSnapshotQuery.data;

  let rhythmEnergyRate = EM_DASH;

  if (offerSnapshot.rhythmKwhRate) {
    rhythmEnergyRate = `${
      offerSnapshot.isMonthToMonth ? "Variable" : "Fixed"
    } ${EM_DASH} ${formatEnergyRate(offerSnapshot.rhythmKwhRate, 5)}
`;
  }

  let buybackRate = EM_DASH;

  if (offerSnapshot.solarEligible && offerSnapshot.solarCreditKwhRate) {
    buybackRate = formatEnergyRate(offerSnapshot.solarCreditKwhRate, 5);
  }

  return (
    <ConfirmationSection label="Plan" error={Boolean(errors)}>
      <BoGrid $variant={GridVariants.Grid4_1}>
        <BoGridColumn>
          <BoInfoContainer $error={errors?.offersnapshotId}>
            <BoInfoLabel>Plan</BoInfoLabel>
            <BoInfoValue>{stringOrEmDash(offerSnapshot.title)}</BoInfoValue>
          </BoInfoContainer>
          <BoInfoContainer $error={errors?.offersnapshotId}>
            <BoInfoLabel>Term</BoInfoLabel>
            <BoInfoValue>{offerSnapshot.termMonths} months</BoInfoValue>
          </BoInfoContainer>
        </BoGridColumn>
        <BoGridColumn>
          <BoInfoContainer $error={errors?.offersnapshotId}>
            <BoInfoLabel>Energy Rate</BoInfoLabel>
            <BoInfoValue>
              {offerSnapshot.isTimeOfUse ? (
                <TOUEnergyBreakdownModals offerSnapshotId={offerSnapshot.id} />
              ) : (
                rhythmEnergyRate
              )}
            </BoInfoValue>
          </BoInfoContainer>
          {offerSnapshot.solarEligible && !offerSnapshot.isTimeOfUse ? (
            <BoInfoContainer $error={errors?.offersnapshotId}>
              <BoInfoLabel>Buyback Energy Rate</BoInfoLabel>
              <BoInfoValue>{buybackRate}</BoInfoValue>
            </BoInfoContainer>
          ) : null}
          <BoInfoContainer $error={errors?.offersnapshotId}>
            <BoInfoLabel>Avg Price (2000 kWh)</BoInfoLabel>
            <BoInfoValue>
              {stringOrEmDash(
                offerSnapshot.price2000Kwh
                  ? formatDollarsToCents(offerSnapshot.price2000Kwh)
                  : null
              )}
            </BoInfoValue>
          </BoInfoContainer>
        </BoGridColumn>
        <BoGridColumn>
          <BoInfoContainer $error={errors?.offersnapshotId}>
            <BoInfoLabel>OfferSnapshot Promo Code</BoInfoLabel>
            <BoInfoValue>
              {stringOrEmDash(offerSnapshot.promo?.code)}
            </BoInfoValue>
          </BoInfoContainer>

          <BoInfoContainer>
            <BoInfoLabel>Referral Code</BoInfoLabel>
            <BoInfoValue>{stringOrEmDash(prospect.referralCode)}</BoInfoValue>
          </BoInfoContainer>
        </BoGridColumn>
        <BoGridColumn>
          <BoInfoContainer $error={errors?.offersnapshotId}>
            <BoInfoValue>
              <DocumentEntry>
                <EFLLink offerId={offerSnapshot.id}>
                  Electricity Facts Label
                </EFLLink>
              </DocumentEntry>
              <DocumentEntry>
                <TOSLink tosUrl={offerSnapshot.termsOfServiceLink("en") || "#"}>
                  Terms of Service
                </TOSLink>
              </DocumentEntry>
              <DocumentEntry>
                <YRACLink yracUrl={offerSnapshot.yracLink("en") || "#"}>
                  Your Rights as a Customer
                </YRACLink>
              </DocumentEntry>
            </BoInfoValue>
          </BoInfoContainer>
        </BoGridColumn>
      </BoGrid>
    </ConfirmationSection>
  );
};
