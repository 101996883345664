import { EnrollmentType } from "@common/enums/customer.enum";
import { AddressFormType } from "@common/types/customerTypes";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { OpsRadioInput } from "@ops-design-system/components/OpsRadioInput/OpsRadioInput";
import { Body1 } from "@ops-design-system/components/Typography/Typography";
import { CommercialMeters } from "@ops-prospect-enroll/components/CommercialMeters/CommercialMeters";
import { useCommercialProspectEnrollment } from "@ops-prospect-enroll/components/CommercialProspectEnrollmentProvider/useCommercialProspectEnrollment";
import { CommercialProspectTabType } from "@ops-prospect-enroll/components/CommercialProspectTabs/CommercialProspectTabs.constants";
import { CommercialSearchAddressForm } from "@ops-prospect-enroll/components/CommercialSearchAddressForm/CommercialSearchAddressForm";
import { CommercialSearchEsiidForm } from "@ops-prospect-enroll/components/CommercialSearchEsiidForm/CommercialSearchEsiidForm";
import {
  StyledCommercialServiceAddressTab,
  StyledCommercialServiceAddressTabSection,
  StyledMetersSearchByRadio,
  StyledMetersSearchByRadiosContainer,
  StyledSaveServiceAddressTabButtons,
  StyledSearchMeterForm,
} from "@ops-prospect-enroll/components/CommercialServiceAddressTab/CommercialServiceAddressTab.styled";
import { useCommercialMetersSearchMutation } from "@ops-prospect-enroll/hooks/mutations/useCommercialMetersSearch.mutation";
import { useUpdateCommercialProspectMutation } from "@ops-prospect-enroll/hooks/mutations/useUpdateCommercialProspect.mutation";
import { DraftMeterEnrollmentType } from "@ops-utils/types/commercialMeterTypes";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

enum SearchMetersBy {
  address = "address",
  esiId = "esiId",
}

interface CommercialServiceAddressTabProps {}

export const CommercialServiceAddressTab = (
  props: CommercialServiceAddressTabProps
) => {
  const prospect = useCommercialProspectEnrollment();
  const navigate = useNavigate();
  const location = useLocation();

  const [searchBy, setSearchBy] = useState<SearchMetersBy>(
    SearchMetersBy.address
  );
  const [meterError, setMeterError] = useState(false);
  const [draftMeters, setDraftMeters] = useState<DraftMeterEnrollmentType[]>(
    prospect.meterEnrollments.map((meter) => ({ ...meter, checked: true }))
  );

  const commercialMetersSearchMutation = useCommercialMetersSearchMutation();
  const updateCommercialProspectMutation =
    useUpdateCommercialProspectMutation();
  const flash = useRhFlash();

  const handleValidate = ({
    esiId = null,
    address = null,
  }: {
    address?: AddressFormType | null;
    esiId?: string | null;
  }) => {
    commercialMetersSearchMutation.mutate(
      {
        body: {
          esiId,
          esiIds: draftMeters.map((m) => m.esiId),
          serviceAddress: address,
        },
      },
      {
        onError: (error) => {
          if (error.status === 400) {
            flash.error(error.data.errors[0].detail);
          }
        },
        onSuccess: (response) => {
          setDraftMeters([
            ...draftMeters,
            {
              addressLine: response.serviceAddress.addressLine1,
              checked: false,
              city: response.serviceAddress.city,
              esiId: response.esiId,
              polrCustomerClass: response.polrCustomerClass,
              premiseType: response.premiseType,
              primary: draftMeters.length === 0,
              state: response.serviceAddress.state,
              unitNumber: response.serviceAddress.unitNumber,
              utility: response.utilityName,
              zipCode: response.serviceAddress.zipCode,
            },
          ]);
        },
      }
    );
  };

  const handleValidateAddress = (address: AddressFormType) => {
    handleValidate({ address });
  };

  const handleValidateEsiid = ({ esiId }: { esiId: string }) =>
    handleValidate({ esiId });

  const handleSave = () => {
    const checkedMeters = draftMeters.filter((m) => m.checked);

    if (checkedMeters.some((meter) => meter.enrollmentType === undefined)) {
      setMeterError(true);
      flash.error("You must select Enrollment Type for every checked address.");
      return;
    }
    setMeterError(false);

    updateCommercialProspectMutation.mutate(
      {
        body: {
          meterEnrollments: checkedMeters.map(
            ({ esiId, enrollmentType }, index) => ({
              enrollmentType: enrollmentType as EnrollmentType,
              esiId,
              primary: index === 0,
            })
          ),
        },
        id: prospect.id,
        uuid: prospect.uuid,
      },
      {
        onError: () =>
          flash.error(
            "There was an error saving the prospect. Please try again."
          ),
        onSuccess: () => flash.success("Successfully saved Service Address."),
      }
    );
  };

  const handleBack = () => {
    handleSave();
    navigate(`${location.pathname}#${CommercialProspectTabType.marketing}`);
  };

  const handleNext = () => {
    handleSave();
    navigate(`${location.pathname}#${CommercialProspectTabType.plan}`);
  };

  return (
    <StyledCommercialServiceAddressTab>
      <StyledCommercialServiceAddressTabSection>
        <Body1 $fontWeight="Bold">Add new meters</Body1>
        <StyledSearchMeterForm>
          <StyledMetersSearchByRadiosContainer>
            <StyledMetersSearchByRadio>
              <OpsRadioInput
                id="search-service-address"
                checked={searchBy === SearchMetersBy.address}
                onChange={() => setSearchBy(SearchMetersBy.address)}
              />
              <Body1 as="label" htmlFor="search-service-address">
                Search by service address
              </Body1>
            </StyledMetersSearchByRadio>
            <StyledMetersSearchByRadio>
              <OpsRadioInput
                id="search-esi-id"
                checked={searchBy === SearchMetersBy.esiId}
                onChange={() => setSearchBy(SearchMetersBy.esiId)}
              />
              <Body1 as="label" htmlFor="search-esi-id">
                Search by ESIID
              </Body1>
            </StyledMetersSearchByRadio>
          </StyledMetersSearchByRadiosContainer>
          {searchBy === SearchMetersBy.address ? (
            <CommercialSearchAddressForm
              handleValidate={handleValidateAddress}
            />
          ) : (
            <CommercialSearchEsiidForm handleValidate={handleValidateEsiid} />
          )}
        </StyledSearchMeterForm>
      </StyledCommercialServiceAddressTabSection>
      {draftMeters.length > 0 ? (
        <StyledCommercialServiceAddressTabSection>
          <Body1 $fontWeight="Bold">Confirm meter</Body1>
          <CommercialMeters
            draftMeters={draftMeters}
            setDraftMeters={setDraftMeters}
            meterError={meterError}
          />
        </StyledCommercialServiceAddressTabSection>
      ) : null}
      <StyledSaveServiceAddressTabButtons>
        <BoButton variant="secondary" onClick={handleBack}>
          Save & Back
        </BoButton>
        <BoButton variant="secondary" onClick={handleSave}>
          Save
        </BoButton>
        <BoButton variant="secondary" onClick={handleNext}>
          Save & Next
        </BoButton>
      </StyledSaveServiceAddressTabButtons>
    </StyledCommercialServiceAddressTab>
  );
};
