import { IdType } from "@common/types/apiTypes";
import {
  formatCurrency,
  formatMonthDayShortYear,
} from "@common/utils/dataFormatters";
import {
  BoCard,
  BoCardLabel,
} from "@ops-design-system/components/BoCard/BoCard";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import {
  BoTable,
  BoTableBody,
  BoTableCell,
  BoTableHead,
  BoTableHeadCell,
  BoTableLabel,
  BoTableRow,
} from "@ops-design-system/components/BoTable/BoTable";
import { ProductContractType } from "@ops-utils/types/productContractTypes";
import { ProductContractTosLink } from "@ops/components/ProductContractTosLink/ProductContractTosLink";
import { useExpiredProductContractsQuery } from "@ops/hooks/queries/useExpiredProductContracts.query";
import React, { PropsWithChildren } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const COLUMNS = [
  "Previous Add On",
  "Policy Number",
  "Contract Start Date",
  "Contract End Date",
  "Cost",
  "Terms and Conditions",
  "Deactivation Date",
];

const StyledTable = styled(BoTable)`
  width: fit-content;
`;

const ProductContractRow = ({
  productContract,
}: {
  productContract: ProductContractType;
}) => {
  const {
    productName,
    tierName,
    rate,
    number,
    startDate,
    endDate,
    agreementEndDate,
    tosUrl,
  } = productContract;

  return (
    <BoTableRow aria-label={`Product Contract - ${number}`}>
      <BoTableCell>{`${productName} - ${tierName}`}</BoTableCell>
      <BoTableCell>{number}</BoTableCell>
      <BoTableCell>{formatMonthDayShortYear(startDate)}</BoTableCell>
      <BoTableCell>{formatMonthDayShortYear(agreementEndDate)}</BoTableCell>
      <BoTableCell>{formatCurrency(rate)}</BoTableCell>
      <BoTableCell>
        <ProductContractTosLink tosUrl={tosUrl} />
      </BoTableCell>
      <BoTableCell>{formatMonthDayShortYear(endDate)}</BoTableCell>
    </BoTableRow>
  );
};

interface BaseTableProps {}
const BaseTable = ({ children }: PropsWithChildren<BaseTableProps>) => {
  return (
    <>
      <BoTableLabel>Add On History</BoTableLabel>
      <StyledTable>
        <BoTableHead>
          <BoTableRow>
            {COLUMNS.map((header) => {
              return <BoTableHeadCell key={header}>{header}</BoTableHeadCell>;
            })}
          </BoTableRow>
        </BoTableHead>
        <BoTableBody>{children}</BoTableBody>
      </StyledTable>
    </>
  );
};

const BaseCard = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <BoCardLabel>Add On History</BoCardLabel>
      <BoCard>{children}</BoCard>
    </>
  );
};

export const ProductContractsHistory = () => {
  const { premiseId } = useParams<{ premiseId: IdType }>();
  const query = useExpiredProductContractsQuery(premiseId);

  if (query.isPending) {
    return (
      <BaseCard>
        <BoCircularProgress />
      </BaseCard>
    );
  }

  if (query.isError) {
    return (
      <BaseCard>
        <div>Error loading data</div>
      </BaseCard>
    );
  }

  if (query.data.count > 0) {
    return (
      <BaseTable>
        {query.data.results.map((productContract) => (
          <ProductContractRow
            productContract={productContract}
            key={`${productContract.productName}-${productContract.number}`}
          />
        ))}
      </BaseTable>
    );
  }

  return (
    <BaseTable>
      <BoTableRow>
        <BoTableCell colSpan={COLUMNS.length} $align="center">
          No Add On History Available
        </BoTableCell>
      </BoTableRow>
    </BaseTable>
  );
};
