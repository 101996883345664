import { Body2 } from "@ops-design-system/components/Typography/Typography";
import { grey, red } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const StyledCommercialMeters = styled.div`
  border: 1px solid ${grey[100]};
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(3)};
  padding: ${rhOpsSpacingPx(2.5)} ${rhOpsSpacingPx(3)};

  &[aria-invalid="true"] {
    border-color: ${red[500]};
  }
`;

export const StyledCommercialMeterRow = styled.div`
  color: ${grey[900]};
  display: flex;
  justify-content: space-between;
`;

export const StyledCommercialMeterColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledCommercialMeterPremiseTypeLabel = styled(Body2)`
  padding-top: ${rhOpsSpacingPx(1)};
`;

export const StyledMeterEnrollmentTypeContainer = styled.div`
  width: 100px;

  &[aria-invalid="true"] {
    color: ${red[500]};
  }

  .react-select__control {
    font-size: 12px;
  }
`;
