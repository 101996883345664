import { BoLabel } from "@ops-design-system/styles/common.styles";
import {
  StyledCommercialSearchEsiidForm,
  StyledEsiidInput,
  StyledValidateButton,
} from "@ops-prospect-enroll/components/CommercialSearchEsiidForm/CommercialSearchEsiidForm.styled";
import React from "react";
import { useForm } from "react-hook-form";

interface CommercialSearchEsiidFormProps {
  handleValidate: (values: CommercialSearchEsiidFormValues) => void;
}

type CommercialSearchEsiidFormValues = {
  esiId: string;
};

export const CommercialSearchEsiidForm = (
  props: CommercialSearchEsiidFormProps
) => {
  const { handleValidate } = props;

  const { register, formState, handleSubmit } =
    useForm<CommercialSearchEsiidFormValues>();

  const { isValid } = formState;

  return (
    <StyledCommercialSearchEsiidForm onSubmit={handleSubmit(handleValidate)}>
      <BoLabel htmlFor="esiId">ESIID</BoLabel>
      <StyledEsiidInput
        id="esiId"
        type="text"
        {...register("esiId", { required: true })}
      />
      <StyledValidateButton
        type="submit"
        variant="secondary"
        disabled={!isValid}
      >
        Validate Address
      </StyledValidateButton>
    </StyledCommercialSearchEsiidForm>
  );
};
