import { RhythmCampaignSlugs } from "@common/constants/campaigns.constant";
import { IdType } from "@common/types/apiTypes";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import { Body1 } from "@ops-design-system/components/Typography/Typography";
import { OfferSnapshot } from "@ops-utils/models/OfferSnapshot.model";
import { OfferCard } from "@ops/components/OfferCard/OfferCard";
import { useOfferSnapshotsQuery } from "@ops/hooks/queries/useOfferSnapshots.query";
import React from "react";

interface AddPremiseOfferSnapshotCardsProps {
  brand: string;
  dunsNumber: string;
  initialSelectedOfferSnapshotId?: IdType | null;
  onOfferSnapshotSelect?: (offerSnapshot: OfferSnapshot) => void;
  rcid: RhythmCampaignSlugs;
}

export const AddPremiseOfferSnapshotCards = (
  props: AddPremiseOfferSnapshotCardsProps
) => {
  const {
    dunsNumber,
    brand,
    rcid,
    initialSelectedOfferSnapshotId,
    onOfferSnapshotSelect,
  } = props;

  const offerSnapshotQuery = useOfferSnapshotsQuery({
    searchOptions: {
      brand,
      campaignSlug: rcid,
      dunsNumber,
      limit: 100,
    },
  });

  if (offerSnapshotQuery.isPending) {
    return (
      <div>
        <BoCircularProgress />
      </div>
    );
  }

  if (offerSnapshotQuery.isError) {
    return (
      <Body1>
        An error occurred. Unable to get offers for this service address.
      </Body1>
    );
  }

  if (offerSnapshotQuery.data.results.length === 0) {
    return (
      <Body1>
        No offers available for this service address. Please try again later.
      </Body1>
    );
  }

  const offerSnapshots = offerSnapshotQuery.data.results;

  return (
    <>
      {offerSnapshots.map((offerSnapshot) => {
        return (
          <OfferCard
            key={offerSnapshot.id}
            offerSnapshot={offerSnapshot}
            active={offerSnapshot.id === initialSelectedOfferSnapshotId}
            onSelect={onOfferSnapshotSelect}
          />
        );
      })}
    </>
  );
};
