import { useFeatureFlagClient } from "@common/components/FeatureFlagClientProvider/useFeatureFlagClient";
import { EM_DASH } from "@common/constants/characters.constant";
import { PaymentMethod } from "@common/models/PaymentMethod.model";
import { FormError } from "@common/types/errorTypes";
import {
  formatCurrency,
  formatMonthDayYear,
} from "@common/utils/dataFormatters";
import { stringOrEmDash } from "@common/utils/stringFormatters";
import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { BoCardVariants } from "@ops-design-system/components/BoCard/BoCard.constants";
import {
  BoInfoContainer,
  BoInfoLabel,
  BoInfoValue,
} from "@ops-design-system/components/BoInfo/BoInfo";
import { Body1 } from "@ops-design-system/components/Typography/Typography";
import { onOff } from "@ops-design-system/utils/booleanHelpers";
import { Prospect } from "@ops-utils/models/Prospect.model";
import { MatchCriteria } from "@ops-utils/types/prospectValidationReportTypes";
import {
  BillingConfirmationGrid,
  BillingConfirmationGridColumn,
  ErrorMessageContainer,
  PaperlessAutoPayValueText,
  WarningMessageText,
} from "@ops/components/ProspectEditor/ConfirmationTab/ConfirmationBillingCard/ConfirmationBillingCard.styled";
import { ConfirmationSection } from "@ops/components/ProspectEditor/ConfirmationTab/ConfirmationSection";
import {
  customerMatchError,
  emailCustomerMatchError,
} from "@ops/constants/errorCodes.constants";
import { useOfferSnapshotQuery } from "@ops/hooks/queries/useOfferSnapshot.query";
import { useProspectPremiseMatchesQuery } from "@ops/hooks/queries/useProspectPremiseMatches.query";
import { usePaymentMethodInState } from "@ops/hooks/usePaymentMethodInState";
import { uniq } from "lodash";
import React, { useEffect } from "react";

interface ConfirmationBillingCardProps {
  errorMessages?: Record<string, string[] | FormError>;
  errors?: Record<string, boolean>;
  prospect: Prospect;
}

interface ErrorMessageProps {
  children: React.ReactNode;
}

const ErrorMessage = ({ children }: ErrorMessageProps) => {
  return (
    <ErrorMessageContainer>
      <Body1>{children}</Body1>
    </ErrorMessageContainer>
  );
};

export const ConfirmationBillingCard = ({
  prospect,
  errors,
  errorMessages,
}: ConfirmationBillingCardProps) => {
  const {
    billingPaymentMethodId,
    enrollmentType,
    depositAmount,
    serviceStartDate,
    isPaperless,
    autopay,
    creditCheckSummary,
    depositAlternativeAmount,
    depositAlternativeElected,
    id: prospectId,
    offersnapshotId,
  } = prospect;
  const {
    requestMonitor,
    paymentMethod: paymentMethodData,
    fetchPaymentMethod,
  } = usePaymentMethodInState(billingPaymentMethodId);

  useEffect(() => {
    fetchPaymentMethod();
  }, [fetchPaymentMethod]);

  let paymentMethod: PaymentMethod | undefined;

  const prospectPremiseMatches = useProspectPremiseMatchesQuery({
    prospectId,
  });
  const premiseMatchData = prospectPremiseMatches.data;

  const offerSnapshotQuery = useOfferSnapshotQuery({
    offerSnapshotId: offersnapshotId ?? "",
    queryOptions: { enabled: Boolean(offersnapshotId) },
  });

  const { featureFlagClient } = useFeatureFlagClient();
  const { opsAlloyValidationReport, opsDepositAlternativeProgram } =
    featureFlagClient.getFlags([
      ["opsAlloyValidationReport", false],
      ["opsDepositAlternativeProgram", false],
    ]);

  const customerMatch =
    prospectPremiseMatches.isSuccess &&
    premiseMatchData &&
    premiseMatchData.length &&
    !creditCheckSummary;

  if (
    billingPaymentMethodId &&
    requestMonitor.didSucceed &&
    paymentMethodData
  ) {
    paymentMethod = PaymentMethod.fromPaymentMethodRetrieve({
      isDefault: true,
      paymentMethodData,
      paymentMethodId: billingPaymentMethodId,
    });
  }

  const emailMatch = Boolean(
    premiseMatchData?.some((test) =>
      test.matchCriteria.includes(MatchCriteria.EMAIL)
    )
  );

  const customerMatchCard = (() => {
    if (opsAlloyValidationReport.value && customerMatch) {
      if (emailMatch) {
        return <ErrorMessage>{emailCustomerMatchError}</ErrorMessage>;
      }
      return <ErrorMessage>{customerMatchError}</ErrorMessage>;
    }
    return null;
  })();

  const depositInfoValue = () => {
    if (!depositAmount) {
      return EM_DASH;
    }

    if (depositAlternativeElected && opsDepositAlternativeProgram.value) {
      return ` ${formatCurrency(
        depositAlternativeAmount ?? "0"
      )} Deposit Alternative Fee`;
    }

    return formatCurrency(depositAmount);
  };

  const offerSnapshot = offerSnapshotQuery?.data;
  const showPaperlessWarning = Boolean(
    offerSnapshot &&
      offerSnapshot.isAutoPayPaperlessDiscountOffer &&
      !isPaperless
  );
  const showAutopayWarning = Boolean(
    offerSnapshot && offerSnapshot.isAutoPayPaperlessDiscountOffer && !autopay
  );
  const showPaperlessAutopayWarning = Boolean(
    showPaperlessWarning || showAutopayWarning
  );

  return (
    <ConfirmationSection
      label="Billing"
      error={Boolean(errors ?? customerMatch)}
    >
      {customerMatchCard}
      {!customerMatch &&
        errorMessages?.creditCheckSummary &&
        uniq(errorMessages.creditCheckSummary as string[]).map((errorMsg) => (
          <ErrorMessage key={errorMsg}>{errorMsg}</ErrorMessage>
        ))}
      <BillingConfirmationGrid>
        <BillingConfirmationGridColumn>
          <BoInfoContainer $error={errors?.enrollmentType}>
            <BoInfoLabel>Service Type</BoInfoLabel>
            <BoInfoValue>{stringOrEmDash(enrollmentType)}</BoInfoValue>
          </BoInfoContainer>
          <BoInfoContainer $error={errors?.creditCheckSummary}>
            <BoInfoLabel>Deposit</BoInfoLabel>
            <BoInfoValue>{depositInfoValue()}</BoInfoValue>
          </BoInfoContainer>
        </BillingConfirmationGridColumn>
        <BillingConfirmationGridColumn>
          <BoInfoContainer $error={errors?.serviceStartDate}>
            <BoInfoLabel>Start Date</BoInfoLabel>
            <BoInfoValue>
              {serviceStartDate
                ? formatMonthDayYear(serviceStartDate)
                : EM_DASH}
            </BoInfoValue>
          </BoInfoContainer>
          <BoInfoContainer $error={errors?.billingPaymentMethodId}>
            <BoInfoLabel>Payment Method</BoInfoLabel>
            <BoInfoValue>
              {paymentMethod ? paymentMethod.companyNameAndNumber : EM_DASH}
            </BoInfoValue>
          </BoInfoContainer>
        </BillingConfirmationGridColumn>
        <BillingConfirmationGridColumn>
          <BoInfoContainer>
            <BoInfoLabel>Paperless</BoInfoLabel>
            <PaperlessAutoPayValueText $isWarning={showPaperlessWarning}>
              {onOff(isPaperless)}
            </PaperlessAutoPayValueText>
          </BoInfoContainer>
          <BoInfoContainer>
            <BoInfoLabel>Auto Pay</BoInfoLabel>
            <PaperlessAutoPayValueText $isWarning={showAutopayWarning}>
              {onOff(Boolean(autopay))}
            </PaperlessAutoPayValueText>
          </BoInfoContainer>
        </BillingConfirmationGridColumn>

        {showPaperlessAutopayWarning ? (
          <BillingConfirmationGridColumn>
            <BoCard $variant={BoCardVariants.Warning}>
              <WarningMessageText>
                The customer must be enrolled in Auto Pay and Paperless to be
                eligible to receive the Auto Pay and Paperless Credit.
              </WarningMessageText>
            </BoCard>
          </BillingConfirmationGridColumn>
        ) : null}
      </BillingConfirmationGrid>
    </ConfirmationSection>
  );
};
