import { IdType } from "@common/types/apiTypes";
import { rhapsodyProductContractClaimsApi } from "@ops-data/api/rhapsodyProductContractClaimsApi";
import { ProductContractClaimKeys } from "@ops/constants/queryKeys.constants";
import { useQuery } from "@tanstack/react-query";

export const useProductContractClaimsQuery = (premiseId: IdType) => {
  return useQuery({
    queryFn: () => rhapsodyProductContractClaimsApi.list(premiseId),
    queryKey: ProductContractClaimKeys.list(premiseId),
  });
};
