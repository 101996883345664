import { addressStates } from "@common/constants/addressStates.constant";
import { AddressFormType } from "@common/types/customerTypes";
import { BoSelectInput } from "@ops-design-system/components/BoSelectInput/BoSelectInput";
import { OpsTextInput } from "@ops-design-system/components/OpsTextInput/OpsTextInput";
import { BoLabel } from "@ops-design-system/styles/common.styles";
import {
  StyledCommercialSearchAddressForm,
  StyledCommercialSearchAddressFormInputContainer,
  StyledCommercialSearchAddressFormRow,
  StyledCommercialSearchAddressValidateButton,
} from "@ops-prospect-enroll/components/CommercialSearchAddressForm/CommercialSearchAddressForm.styled";
import React from "react";
import { Controller, useForm } from "react-hook-form";

interface CommercialSearchAddressFormProps {
  handleValidate: (data: AddressFormType) => void;
}

const stateOptions = addressStates.map((state) => ({
  label: state.name,
  value: state.abbreviation,
}));

export const CommercialSearchAddressForm = ({
  handleValidate,
}: CommercialSearchAddressFormProps) => {
  const { register, formState, handleSubmit, control } =
    useForm<AddressFormType>({ defaultValues: { state: "TX" } });
  const { isValid } = formState;

  return (
    <StyledCommercialSearchAddressForm onSubmit={handleSubmit(handleValidate)}>
      <StyledCommercialSearchAddressFormRow>
        <StyledCommercialSearchAddressFormInputContainer>
          <BoLabel htmlFor="addressLine1">Street Address</BoLabel>
          <OpsTextInput
            id="addressLine1"
            {...register("addressLine1", { required: true })}
          />
        </StyledCommercialSearchAddressFormInputContainer>
        <StyledCommercialSearchAddressFormInputContainer>
          <BoLabel htmlFor="unitNumber">Unit #</BoLabel>
          <OpsTextInput id="unitNumber" {...register("unitNumber")} />
        </StyledCommercialSearchAddressFormInputContainer>
      </StyledCommercialSearchAddressFormRow>
      <StyledCommercialSearchAddressFormRow>
        <StyledCommercialSearchAddressFormInputContainer>
          <BoLabel htmlFor="city">City</BoLabel>
          <OpsTextInput id="city" {...register("city", { required: true })} />
        </StyledCommercialSearchAddressFormInputContainer>
        <StyledCommercialSearchAddressFormInputContainer>
          <BoLabel htmlFor="state">State</BoLabel>
          <Controller
            control={control}
            name="state"
            rules={{ required: true }}
            render={({ field: { onBlur, onChange, value } }) => (
              <BoSelectInput
                id="state"
                onBlur={onBlur}
                onChange={(option) => onChange(option?.value)}
                value={
                  stateOptions.find((option) => option.value === value)?.value
                }
                options={stateOptions}
              />
            )}
          />
        </StyledCommercialSearchAddressFormInputContainer>
        <StyledCommercialSearchAddressFormInputContainer>
          <BoLabel htmlFor="zipCode">Zip Code</BoLabel>
          <OpsTextInput
            id="zipCode"
            {...register("zipCode", { required: true })}
          />
        </StyledCommercialSearchAddressFormInputContainer>
      </StyledCommercialSearchAddressFormRow>
      <StyledCommercialSearchAddressValidateButton
        variant="secondary"
        disabled={!isValid}
        type="submit"
      >
        Validate Address
      </StyledCommercialSearchAddressValidateButton>
    </StyledCommercialSearchAddressForm>
  );
};
