import { useFeatureFlagClient } from "@common/components/FeatureFlagClientProvider/useFeatureFlagClient";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { ReactComponent as PlusInsideCircle } from "@design-system/icons/PlusInsideCircle.svg";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import { BoDivider } from "@ops-design-system/components/BoDivider/BoDivider";
import { Body2, H1 } from "@ops-design-system/components/Typography/Typography";
import { useCreateCommercialProspectMutation } from "@ops-prospect-enroll/hooks/mutations/useCreateCommercialProspect.mutation";
import { useCreateResidentialProspectMutation } from "@ops-prospect-enroll/hooks/mutations/useCreateResidentialProspect.mutation";
import { useProspectListQuery } from "@ops-prospect-enroll/hooks/queries/useProspectList.query";
import { ProspectSearchRequestType } from "@ops-utils/types/prospectTypes";
import { ProspectsSearchForm } from "@ops/components/ProspectsSearchForm/ProspectsSearchForm";
import { ProspectsTable } from "@ops/components/ProspectsTable/ProspectsTable";
import {
  ProspectsPageWrapper,
  StyledProspectPageCreateButtonsContainer,
  StyledProspectPageTopContainer,
} from "@ops/pages/ProspectsPage/ProspectsPage.styled";
import { commercialProspectPath, prospectPath } from "@ops/routes/routePaths";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

interface ProspectResultsProps {
  searchOptions: ProspectSearchRequestType;
}
const ProspectResults = (props: ProspectResultsProps) => {
  const { searchOptions } = props;

  const prospectListQuery = useProspectListQuery({
    queryOptions: {
      enabled: Object.values(searchOptions).some(Boolean),
    },
    searchOptions,
  });

  if (prospectListQuery.isPending) {
    return <BoCircularProgress />;
  }

  if (prospectListQuery.isError) {
    return <Body2>An error occurred. Unable to search prospects</Body2>;
  }

  const prospects = prospectListQuery.data;

  if (prospects.length === 0) {
    return <Body2>No prospects found</Body2>;
  }
  return <ProspectsTable prospects={prospects} />;
};

export const ProspectsPage = () => {
  const [prospectSearchOptions, setProspectSearchOptions] =
    useState<ProspectSearchRequestType>({});
  const { featureFlagClient } = useFeatureFlagClient();

  const { opsCommercialEnrollment } = featureFlagClient.getFlags([
    ["opsCommercialEnrollment", false],
  ]);

  const navigate = useNavigate();

  const createResidentialProspectMutation =
    useCreateResidentialProspectMutation();

  const createCommercialProspectMutation =
    useCreateCommercialProspectMutation();

  const flash = useRhFlash();

  const handleCreateResidentialProspectClick = () => {
    createResidentialProspectMutation.mutate(undefined, {
      onError: () => flash.error("Could not create residential prospect"),
      onSuccess: (data) => {
        navigate(prospectPath(data.id));
      },
    });
  };

  const handleCreateCommercialProspectClick = () => {
    createCommercialProspectMutation.mutate(undefined, {
      onError: () => flash.error("Could not create commercial prospect"),
      onSuccess: (data) =>
        navigate(`${commercialProspectPath(data.id)}#marketing`),
    });
  };

  const showResults = Object.values(prospectSearchOptions).some(Boolean);

  return (
    <ProspectsPageWrapper>
      <StyledProspectPageTopContainer>
        <H1>Prospects</H1>

        <StyledProspectPageCreateButtonsContainer>
          <BoButton
            variant="secondary"
            size="extraSmall"
            icon={PlusInsideCircle}
            onClick={handleCreateResidentialProspectClick}
            loading={createResidentialProspectMutation.isPending}
          >
            {opsCommercialEnrollment.value
              ? "New Residential Prospect"
              : "New Prospect"}
          </BoButton>
          {opsCommercialEnrollment.value ? (
            <BoButton
              variant="secondary"
              size="extraSmall"
              icon={PlusInsideCircle}
              onClick={handleCreateCommercialProspectClick}
              loading={createCommercialProspectMutation.isPending}
            >
              New Commercial Prospect
            </BoButton>
          ) : null}
        </StyledProspectPageCreateButtonsContainer>
      </StyledProspectPageTopContainer>
      <BoDivider />
      <ProspectsSearchForm onSubmit={setProspectSearchOptions} />

      {showResults ? (
        <ProspectResults searchOptions={prospectSearchOptions} />
      ) : null}
    </ProspectsPageWrapper>
  );
};
