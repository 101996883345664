import { rhapsodyCommercialMetersApi } from "@ops-data/api/rhapsodyCommercialMetersApi";
import { SearchCommercialMetersRequestType } from "@ops-utils/types/commercialMeterTypes";
import { useMutation } from "@tanstack/react-query";

interface UseCommercialMetersSearchMutationProps {
  body: SearchCommercialMetersRequestType;
}

export const useCommercialMetersSearchMutation = () => {
  return useMutation({
    mutationFn: ({ body }: UseCommercialMetersSearchMutationProps) =>
      rhapsodyCommercialMetersApi.search(body),
  });
};
