import { IdType } from "@common/types/apiTypes";
import { rhapsodyCommercialProspectApi } from "@ops-data/api/rhapsodyCommercialProspectApi";
import { CommercialProspectQueryKeys } from "@ops-data/constants/queryKeys.constants";
import { useQuery } from "@tanstack/react-query";

interface UseCommercialProspectQueryProps {
  prospectId: IdType;
  queryOptions: {
    enabled?: boolean;
  };
}
export const useCommercialProspectQuery = (
  props: UseCommercialProspectQueryProps
) => {
  const { prospectId, queryOptions: { enabled = true } = {} } = props;

  return useQuery({
    enabled,
    queryFn: () => rhapsodyCommercialProspectApi.retrieve(prospectId),
    queryKey: CommercialProspectQueryKeys.retrieve(prospectId),
  });
};
