import { formatPhoneNumber } from "@common/utils/dataFormatters";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import { BoSelectInput } from "@ops-design-system/components/BoSelectInput/BoSelectInput";
import { Body2 } from "@ops-design-system/components/Typography/Typography";
import { BoLabel } from "@ops-design-system/styles/common.styles";
import {
  StyledAcquisitionCampaignContainer,
  StyledCommercialMarketingTab,
  StyledSaveMarketingTabButtons,
} from "@ops-prospect-enroll/components/CommercialMarketingTab/CommercialMarketingTab.styled";
import { useCommercialProspectEnrollment } from "@ops-prospect-enroll/components/CommercialProspectEnrollmentProvider/useCommercialProspectEnrollment";
import { CommercialProspectTabType } from "@ops-prospect-enroll/components/CommercialProspectTabs/CommercialProspectTabs.constants";
import { useUpdateCommercialProspectMutation } from "@ops-prospect-enroll/hooks/mutations/useUpdateCommercialProspect.mutation";
import { useAcquisitionCampaignsQuery } from "@ops-prospect-enroll/hooks/queries/useAcquisitionCampaigns.query";
import React, { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const CommercialMarketingTab = () => {
  const prospect = useCommercialProspectEnrollment();
  const [selectedCampaignSlug, setSelectedCampaignSlug] = useState<
    null | string
  >(prospect.acquisition?.details.slug || null);

  const acquisitionCampaignsQuery = useAcquisitionCampaignsQuery();
  const updateCommercialProspectMutation =
    useUpdateCommercialProspectMutation();
  const flash = useRhFlash();
  const navigate = useNavigate();
  const location = useLocation();

  const campaignOptions = useMemo(() => {
    if (
      acquisitionCampaignsQuery.isPending ||
      acquisitionCampaignsQuery.isError
    ) {
      return [];
    }

    const options = acquisitionCampaignsQuery.data
      .filter((campaign) => campaign.active)
      .map(({ name, phone, slug }) => ({
        label: `${name} ${formatPhoneNumber(phone)}`,
        value: slug,
      }));

    const currentCampaign = acquisitionCampaignsQuery.data.find(
      (campaign) => campaign.slug === selectedCampaignSlug
    );

    if (selectedCampaignSlug && currentCampaign?.active === false) {
      options.unshift({
        label: `${currentCampaign.name} ${formatPhoneNumber(currentCampaign.phone)} - Inactive, please choose an active campaign`,
        value: selectedCampaignSlug,
      });
    }

    return options;
  }, [
    acquisitionCampaignsQuery.data,
    acquisitionCampaignsQuery.isError,
    acquisitionCampaignsQuery.isPending,
    selectedCampaignSlug,
  ]);

  if (acquisitionCampaignsQuery.isPending) {
    return <BoCircularProgress />;
  }

  if (acquisitionCampaignsQuery.isError) {
    return <Body2>Failed to get Acquisition campaigns</Body2>;
  }

  const handleSave = () => {
    if (
      !selectedCampaignSlug ||
      selectedCampaignSlug === prospect.acquisition?.details.slug
    ) {
      return;
    }

    updateCommercialProspectMutation.mutate(
      {
        body: { acquisitionCampaignSlug: selectedCampaignSlug },
        id: prospect.id,
        uuid: prospect.uuid,
      },
      {
        onError: () => {
          flash.error(
            "There was an error saving the prospect. Please try again."
          );
        },
        onSuccess: () => flash.success("Successfully saved Marketing."),
      }
    );
  };

  const handleNext = () => {
    handleSave();

    navigate(
      `${location.pathname}#${CommercialProspectTabType.serviceAddress}`
    );
  };

  return (
    <StyledCommercialMarketingTab>
      <StyledAcquisitionCampaignContainer>
        <BoLabel id="campaign-select">Campaign</BoLabel>
        <BoSelectInput
          ariaLabelledby="campaign-select"
          value={selectedCampaignSlug}
          onChange={(option) =>
            setSelectedCampaignSlug(option ? (option.value as string) : null)
          }
          options={campaignOptions}
        />
      </StyledAcquisitionCampaignContainer>
      <StyledSaveMarketingTabButtons>
        <BoButton disabled variant="secondary">
          Save & Back
        </BoButton>
        <BoButton variant="secondary" onClick={handleSave}>
          Save
        </BoButton>
        <BoButton variant="secondary" onClick={handleNext}>
          Save & Next
        </BoButton>
      </StyledSaveMarketingTabButtons>
    </StyledCommercialMarketingTab>
  );
};
