import { affiliateApi } from "@common/api/affiliateApi";
import { AffiliateQueryKeys } from "@ops/constants/queryKeys.constants";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useAffiliateUpdateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: affiliateApi.update,
    onSuccess: (_, { affiliateId }) => {
      queryClient.invalidateQueries({
        queryKey: AffiliateQueryKeys.retrieve(affiliateId),
      });
    },
  });
};
