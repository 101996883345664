import { api } from "@common/api/api";
import { IdType } from "@common/types/apiTypes";
import { ContractQueryKeys } from "@ops/constants/queryKeys.constants";
import { useQuery } from "@tanstack/react-query";

export const useContractExpirationNoticesQuery = (premiseId: IdType) => {
  return useQuery({
    queryFn: () => api.contracts.expirationNotices.list(premiseId),
    queryKey: [...ContractQueryKeys.CONTRACT_EXPIRATION_NOTICES, premiseId],
  });
};
