import { AppBrands } from "@common/enums/appBrands.enum";
import { AccountType } from "@common/enums/customer.enum";
import { getEnvApplicationSettings } from "@common/services/getEnvApplicationSettings.service";
import { IdType } from "@common/types/apiTypes";
import { allEnvApplicationSettings } from "@ops/settings/env";

const envApplicationSettings = getEnvApplicationSettings(
  allEnvApplicationSettings
);

export const RHYTHM_MARKETING_URL = envApplicationSettings.marketingUrl;
export const RHYTHM_PORTAL_URL = envApplicationSettings.portalUrl;
export const PORTAL_SIGN_UP_PATHNAME = "sign-up";

export const signUpBasePath = () => {
  return `/${PORTAL_SIGN_UP_PATHNAME}`;
};

export const RHYTHM_ITERABLE_ID = envApplicationSettings.iterableProjectId;
export const RHYTHM_COMMERCIAL_ITERABLE_ID =
  envApplicationSettings.iterableCommercialProjectId;
export const ENERGY_TEXAS_ITERABLE_ID =
  envApplicationSettings.energyTexasIterableProjectId;

const iterableBrandMapping = {
  [AppBrands.RHYTHM]: RHYTHM_ITERABLE_ID,
  [AppBrands.ENERGY_TEXAS]: ENERGY_TEXAS_ITERABLE_ID,
};

export const getIterableUrl = (
  email: string,
  brand: AppBrands,
  id: IdType,
  accountType: AccountType
) => {
  if (brand === AppBrands.ENERGY_TEXAS) {
    return `https://app.iterable.com/users/profiles/${id}/fields?projectId=${iterableBrandMapping[brand]}`;
  } else if (accountType === AccountType.SMALL_COMMERCIAL) {
    return `https://app.iterable.com/users/profiles/${id}/fields?projectId=${RHYTHM_COMMERCIAL_ITERABLE_ID}`;
  } else {
    return `https://app.iterable.com/users/profiles/${email}/fields?projectId=${iterableBrandMapping[brand]}`;
  }
};
