import { IdType } from "@common/types/apiTypes";
import { useProspectAvailableOfferSnapshotsQuery } from "@ops-data/hooks/queries/useProspectAvailableOfferSnapshots.query";
import { BoCard } from "@ops-design-system/components/BoCard/BoCard";
import { BoCircularProgress } from "@ops-design-system/components/BoCircularProgress/BoCircularProgress";
import { Body1 } from "@ops-design-system/components/Typography/Typography";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { OfferSnapshot } from "@ops-utils/models/OfferSnapshot.model";
import { OfferCard } from "@ops/components/OfferCard/OfferCard";
import { useEnrollmentFlowContext } from "@ops/hooks/contexts/useEnrollmentFlowContext";
import React, { useState } from "react";
import styled from "styled-components";

interface PlansTabOfferSnapshotCardsProps {
  initialOfferSnapshotId: IdType | null;
  setSelectedOfferSnapshot: (offerSnapshot: OfferSnapshot) => void;
}

const StyledBoCard = styled(BoCard)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${rhOpsSpacingPx(2)};
  justify-content: center;
`;

export const PlansTabOfferSnapshotCards = (
  props: PlansTabOfferSnapshotCardsProps
) => {
  const { setSelectedOfferSnapshot, initialOfferSnapshotId } = props;
  const { prospect } = useEnrollmentFlowContext();

  const [selectedOfferSnapshotId, setSelectedOfferSnapshotId] =
    useState<IdType | null>(initialOfferSnapshotId);

  const availableOfferSnapshotsQuery = useProspectAvailableOfferSnapshotsQuery({
    prospectId: prospect.id,
    queryOptions: {
      enabled: Boolean(prospect.dunsNumber) || Boolean(prospect.zipCode),
      retry: false,
    },
  });

  if (availableOfferSnapshotsQuery.isPending) {
    return (
      <div>
        <BoCircularProgress />
      </div>
    );
  }

  if (availableOfferSnapshotsQuery.isError) {
    return (
      <StyledBoCard>
        <Body1>
          An error occurred. Unable to get offers for this service address.
        </Body1>
      </StyledBoCard>
    );
  }

  const offerSnapshots = availableOfferSnapshotsQuery.data;

  return (
    <StyledBoCard>
      {offerSnapshots.map((offerSnapshot) => {
        const handleSelect = () => {
          setSelectedOfferSnapshot(offerSnapshot);
          setSelectedOfferSnapshotId(offerSnapshot.id);
        };

        return (
          <OfferCard
            key={offerSnapshot.id}
            offerSnapshot={offerSnapshot}
            onSelect={handleSelect}
            active={offerSnapshot.id === selectedOfferSnapshotId}
          />
        );
      })}
    </StyledBoCard>
  );
};
