import { ISO8601_DATE_FORMAT } from "@common/constants/date.constant";
import { IdType } from "@common/types/apiTypes";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { BoDateInput } from "@ops-design-system/components/BoDate/BoDate";
import { Subtitle2 } from "@ops-design-system/components/Typography/Typography";
import { BoLabel } from "@ops-design-system/styles/common.styles";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import { useServiceStartDatesQuery } from "@ops/hooks/queries/useServiceStartDates.query";
import dayjs from "dayjs";
import React from "react";
import styled from "styled-components";

interface ServiceDatePickerProps {
  allowAnyDaysInTheFuture?: boolean;
  allowAnyDaysInThePast?: boolean;
  disabled?: boolean;
  header?: string | null;
  label: string;
  name: string;
  offerSnapshotId?: IdType;
  onChange?: (dateString: string | null) => void;
  // overrideMinimumDayToStartService, if rhythm decides there is a minimum date for users to sign up, we can override that with this prop
  overrideMinimumDayToStartService?: boolean;
  value: string | null;
}

const ServiceDatePickerContainer = styled.div`
  min-width: 250px;
`;

const HeaderContainer = styled.div`
  padding-bottom: ${rhOpsSpacingPx(0.5)};
`;

export const ServiceDatePicker = (props: ServiceDatePickerProps) => {
  const {
    header,
    label,
    disabled,
    allowAnyDaysInTheFuture,
    allowAnyDaysInThePast,
    name,
    offerSnapshotId,
    overrideMinimumDayToStartService = true,
    ...inputProps
  } = props;
  const flash = useRhFlash();
  const dateQuery = useServiceStartDatesQuery({
    enabled: !allowAnyDaysInTheFuture,
    offerSnapshotId,
    overrideMinimumDayToStartService,
  });

  if (dateQuery.isError) {
    flash.error("Problem retrieving allowed service dates");
  }

  const allowedDates = dateQuery.data;

  const today = dayjs().format(ISO8601_DATE_FORMAT);
  const minDate = allowAnyDaysInThePast
    ? undefined
    : (allowedDates?.[0] ?? today);
  const maxDate = allowAnyDaysInTheFuture
    ? undefined
    : allowedDates?.[allowedDates.length - 1];

  return (
    <ServiceDatePickerContainer>
      <HeaderContainer>
        {header && (
          <Subtitle2 $fontWeight="Bold" as="div">
            {header}
          </Subtitle2>
        )}
      </HeaderContainer>
      <BoLabel htmlFor={name}>{label}</BoLabel>
      <BoDateInput
        name={name}
        label=""
        placeholder="mm/dd/yyyy"
        minDate={minDate}
        maxDate={maxDate}
        allowedDates={allowAnyDaysInTheFuture ? undefined : allowedDates}
        disabled={dateQuery.isPending || disabled}
        closeOnSelect
        {...inputProps}
      />
    </ServiceDatePickerContainer>
  );
};
