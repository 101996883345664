import { BoDivider } from "@ops-design-system/components/BoDivider/BoDivider";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const ContractSwapDivider = styled(BoDivider)`
  margin-bottom: ${rhOpsSpacingPx(3)};
  margin-top: ${rhOpsSpacingPx(3)};
`;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NewContractDatesContainer = styled.div`
  display: flex;
  gap: ${rhOpsSpacingPx(9)};
  margin-bottom: ${rhOpsSpacingPx(3)};
`;

export const OfferSnapshotSection = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${rhOpsSpacingPx(2)};
  margin-top: ${rhOpsSpacingPx(2)};
`;
