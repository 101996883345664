import { OktaGroups } from "@common/constants/okta.constant";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { LoginCallback, useOktaAuth } from "@okta/okta-react";
import { AcquisitionCampaignsPage } from "@ops/pages/Admin/AcquisitionCampaignsPage/AcquisitionCampaignsPage";
import { FidelityPaymentsTablePage } from "@ops/pages/Admin/FidelityPaymentsTablePage/FidelityPaymentsTablePage";
import { ManageContactLogsPage } from "@ops/pages/Admin/ManageContactLogsPage/ManageContactLogsPage";
import { ReprocessInvoicePage } from "@ops/pages/Admin/ReprocessInvoicePage/ReprocessInvoicePage";
import { TransferEdiMessagePage } from "@ops/pages/Admin/TransferEdiMessagePage/TransferEdiMessagePage";
import { AdminHomePage } from "@ops/pages/AdminHomePage/AdminHomePage";
import { AffiliateDetailPage } from "@ops/pages/AffiliateDetailPage/AffiliateDetailPage";
import { AffiliatesPage } from "@ops/pages/AffiliatesPage/AffiliatesPage";
import { CommercialProspectEnrollPage } from "@ops/pages/CommercialProspectEnrollPage/CommercialProspectEnrollPage";
import { CustomerPage } from "@ops/pages/CustomerPage/CustomerPage";
import { CustomerSearchPage } from "@ops/pages/CustomerSearchPage/CustomerSearchPage";
import { EditAffiliatePage } from "@ops/pages/EditAffiliatePage/EditAffiliatePage";
import { EnrollmentPage } from "@ops/pages/EnrollmentPage/EnrollmentPage";
import { Error404Page } from "@ops/pages/Error404Page/Error404Page";
import { FinanceDashboardPage } from "@ops/pages/FinanceDashboardPage/FinanceDashboardPage";
import { FinanceFampPage } from "@ops/pages/FinanceFampPage/FinanceFampPage";
import { NewAffiliatePage } from "@ops/pages/NewAffiliatePage/NewAffiliatePage";
import { ProspectsPage } from "@ops/pages/ProspectsPage/ProspectsPage";
import { RedirectCustomerPage } from "@ops/pages/RedirectCustomerPage/RedirectCustomerPage";
import { SignInPage } from "@ops/pages/SignInPage/SignInPage";
import { UnauthorizedPage } from "@ops/pages/UnauthorizedPage/UnauthorizedPage";
import { IndexPageRedirect } from "@ops/routes/IndexPageRedirect";
import {
  adminPaths,
  affiliatesPath,
  commercialProspectRootPath,
  financeRootPath,
  loginCallbackPath,
  logoutPath,
  prospectsHomePath,
  signInPath,
  unauthorizedPath,
} from "@ops/routes/routePaths";
import { SecureOutlet } from "@ops/routes/SecureOutlet";
import { withSentryReactRouterV6Routing as SentryWithSentryReactRouterV6Routing } from "@sentry/react";
import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";

// See https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/#usage-with-routes--component
const SentryRoutes = SentryWithSentryReactRouterV6Routing(Routes);

function LogoutRoute() {
  const { oktaAuth } = useOktaAuth();
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  useEffect(() => {
    setIsLoggingOut(true);

    oktaAuth.signOut().catch((error) => {
      // eslint-disable-next-line no-console -- We want to see this in our logging tools
      console.error(error);
      throw new Error("Error logging out");
    });

    return () => {
      setIsLoggingOut(false);
    };
  }, [oktaAuth]);

  return isLoggingOut ? <RhCircularProgress /> : null;
}

const AdminRoutes = () => {
  return (
    <SentryRoutes>
      <Route element={<SecureOutlet allowedGroups={[OktaGroups.Ops]} />}>
        <Route index element={<AdminHomePage />} />
        <Route
          path="fidelity-payments"
          element={<FidelityPaymentsTablePage />}
        />
        <Route
          path="acquisition-campaigns"
          element={<AcquisitionCampaignsPage />}
        />
        <Route path="reprocess-invoice" element={<ReprocessInvoicePage />} />
        <Route
          path="transfer-edi-message"
          element={<TransferEdiMessagePage />}
        />
        <Route path="contact-logs" element={<ManageContactLogsPage />} />
      </Route>
    </SentryRoutes>
  );
};

const ProspectRoutes = () => {
  return (
    <SentryRoutes>
      <Route
        element={
          <SecureOutlet
            allowedGroups={[
              OktaGroups.Ops,
              OktaGroups.CSRs,
              OktaGroups.CSRTier2,
              OktaGroups.CSRTier3,
            ]}
          />
        }
      >
        <Route index element={<ProspectsPage />} />
        <Route path=":id" element={<EnrollmentPage />} />
      </Route>
    </SentryRoutes>
  );
};

const CommercialProspectRoutes = () => {
  return (
    <SentryRoutes>
      <Route
        element={
          <SecureOutlet
            allowedGroups={[
              OktaGroups.Ops,
              OktaGroups.CSRs,
              OktaGroups.CSRTier2,
              OktaGroups.CSRTier3,
            ]}
          />
        }
      >
        <Route path=":id" element={<CommercialProspectEnrollPage />} />
      </Route>
    </SentryRoutes>
  );
};

const CustomerRoutes = () => {
  return (
    <SentryRoutes>
      <Route
        element={
          <SecureOutlet
            allowedGroups={[
              OktaGroups.Ops,
              OktaGroups.CSRs,
              OktaGroups.CSRTier2,
              OktaGroups.CSRTier3,
            ]}
          />
        }
      >
        <Route path="search" element={<CustomerSearchPage />} />
      </Route>
    </SentryRoutes>
  );
};

const CustomerOpsRoutes = () => {
  return (
    <SentryRoutes>
      <Route
        element={
          <SecureOutlet
            allowedGroups={[
              OktaGroups.Ops,
              OktaGroups.CSRs,
              OktaGroups.CSRTier2,
              OktaGroups.CSRTier3,
            ]}
          />
        }
      >
        <Route
          path="customers/:id/premise/:premiseId"
          element={<CustomerPage />}
        />
        <Route path="customers/:id" element={<RedirectCustomerPage />} />
      </Route>
    </SentryRoutes>
  );
};

const FinanceRoutes = () => {
  return (
    <SentryRoutes>
      <Route element={<SecureOutlet allowedGroups={[OktaGroups.Finance]} />}>
        <Route index element={<FinanceDashboardPage />} />
        <Route path="famp" element={<FinanceFampPage />} />
      </Route>
    </SentryRoutes>
  );
};

const AffiliateRoutes = () => {
  return (
    <SentryRoutes>
      <Route element={<SecureOutlet allowedGroups={[OktaGroups.Ops]} />}>
        <Route index element={<AffiliatesPage />} />
        <Route path="new" element={<NewAffiliatePage />} />
        <Route path=":id" element={<AffiliateDetailPage />} />
        <Route path=":id/edit" element={<EditAffiliatePage />} />
      </Route>
    </SentryRoutes>
  );
};

export const RhapsodyRoutes = () => {
  return (
    <SentryRoutes>
      {/* App routes */}
      <Route path={`${financeRootPath()}/*`} element={<FinanceRoutes />} />
      <Route path={`${affiliatesPath()}/*`} element={<AffiliateRoutes />} />
      <Route path={`${adminPaths.home()}/*`} element={<AdminRoutes />} />
      <Route path={`${prospectsHomePath()}/*`} element={<ProspectRoutes />} />
      <Route
        path={`${commercialProspectRootPath}/*`}
        element={<CommercialProspectRoutes />}
      />
      <Route path="customers/*" element={<CustomerRoutes />} />
      <Route path="ops/*" element={<CustomerOpsRoutes />} />

      {/* Sign-in */}
      <Route path={signInPath()} element={<SignInPage />} />
      <Route path={logoutPath()} element={<LogoutRoute />} />
      <Route path={loginCallbackPath()} element={<LoginCallback />} />

      {/* Misc. */}
      <Route path={unauthorizedPath()} element={<UnauthorizedPage />} />
      <Route index element={<IndexPageRedirect />} />

      {/* Splat route (everything else), 404 */}
      <Route path="*" element={<Error404Page />} />
    </SentryRoutes>
  );
};
