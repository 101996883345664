import { grey } from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const StyledCommercialProspectEnrollPage = styled.div`
  column-gap: ${rhOpsSpacingPx(5)};
  display: grid;
  grid-template:
    "item1 item3"
    "item2 item3" 1fr / 2fr 1fr;
  row-gap: ${rhOpsSpacingPx(3)};
  width: 100%;
`;

export const StyledCommercialProspectEnrollPageTop = styled.div`
  display: flex;
  grid-area: item1;
  justify-content: space-between;
`;

export const StyledCommercialProspectEnrollPageTitle = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  gap: ${rhOpsSpacingPx(1)};
`;

export const StyledProspectId = styled.div`
  border: 1px solid ${grey[100]};
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: ${rhOpsSpacingPx(2)};
`;

export const StyledSupportLogsArea = styled.div`
  grid-area: item3;
`;
