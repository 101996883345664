import { EventLogBaseData } from "@ops-utils/types/eventLogTypes";
import { EventLog } from "@ops/models/EventLog/EventLog.model";

export interface CustomerSetPasswordData extends EventLogBaseData {}

export class CustomerSetPasswordSuccessfully extends EventLog {
  constructor({ ...baseEventData }: CustomerSetPasswordData) {
    super(baseEventData);
  }
}
