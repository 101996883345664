import { darkPurple, grey } from "@ops-design-system/styles/palette.colors";
import styled from "styled-components";

export const OpsTextInput = styled.input.attrs({ type: "text" })`
  background: none;
  border: none;
  border-bottom: 1px solid ${grey["300"]};
  border-radius: 0;
  color: ${grey["900"]};
  font-size: 16px;
  margin: 0;
  margin-bottom: -1px;
  padding: 11px 10px 11px 0;
  width: 100%;

  &:active {
    outline: none;
  }

  &:focus {
    border-bottom-color: ${darkPurple["500"]};
    border-bottom-width: 2px;
    margin-bottom: -2px;
    outline: none;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  &:hover {
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
`;
