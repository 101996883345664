import { BoButton } from "@ops-design-system/components/BoButton/BoButton";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const StyledCommercialSearchAddressForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${rhOpsSpacingPx(3)};
  height: 100%;
`;

export const StyledCommercialSearchAddressFormRow = styled.div`
  display: flex;
  gap: ${rhOpsSpacingPx(2.5)};

  :first-child {
    flex-grow: 1;
  }
`;

export const StyledCommercialSearchAddressFormInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 200px;
`;

export const StyledCommercialSearchAddressValidateButton = styled(BoButton)`
  margin-top: auto;
  width: 150px;
`;
