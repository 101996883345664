import { useFeatureFlagClient } from "@common/components/FeatureFlagClientProvider/useFeatureFlagClient";
import { AppBrands } from "@common/enums/appBrands.enum";
import {
  BoButtonTab,
  BoButtonTabContainer,
} from "@ops-design-system/components/BoButtonTab/BoButtonTab";
import {
  CustomerTabType,
  customerTabs,
} from "@ops-utils/types/customerDashboardTypes";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const tabNameMapping = {
  "#add-ons": "Add-Ons",
  "#admin": "Admin",
  "#billing": "Billing",
  "#customer": "Customer",
  "#devices": "Devices",
  "#documents": "Documents",
  "#operations": "Operations",
  "#retention": "Retention",
  "#rewards": "Rewards",
  "#usage": "Usage",
};

interface CustomerDashboardTabsProps {
  customerBrand: AppBrands;
}

export const CustomerDashboardTabs = (props: CustomerDashboardTabsProps) => {
  const { customerBrand } = props;
  const navigate = useNavigate();
  const { hash } = useLocation();

  const handleClick = (tab: CustomerTabType) => {
    navigate(tab);
  };

  useEffect(() => {
    // #add-ons is still a valid tab for non-rhythm customers, so let's make sure they can never navigate to it
    if (customerBrand !== AppBrands.RHYTHM && hash === "#add-ons") {
      navigate("#customer");
    }
  }, [customerBrand, hash, navigate]);

  const { featureFlagClient } = useFeatureFlagClient();

  const { opsDocumentsTab } = featureFlagClient.getFlags([
    ["opsDocumentsTab", false],
  ]);

  return (
    <BoButtonTabContainer>
      {customerTabs
        .filter((tabName) =>
          opsDocumentsTab.value ? true : tabName !== "#documents"
        )
        .filter((tabName) => {
          return customerBrand === AppBrands.RHYTHM
            ? true
            : tabName !== "#add-ons";
        })
        .map((tabName) => {
          const mappedName = tabNameMapping[tabName];

          return (
            <BoButtonTab
              key={tabName}
              data-active={hash === tabName}
              onClick={() => handleClick(tabName)}
            >
              {mappedName}
            </BoButtonTab>
          );
        })}
    </BoButtonTabContainer>
  );
};
