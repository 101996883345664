import { BoModalPositions } from "@ops-design-system/components/BoModal/BoModal.constants";
import { ReactComponent as XIcon } from "@ops-design-system/icons/XIcon.svg";
import { borders } from "@ops-design-system/styles/borders";
import {
  darkPurple,
  trueWhite,
} from "@ops-design-system/styles/palette.colors";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import React, { ComponentType, FC } from "react";
import ReactModal, { defaultStyles } from "react-modal";
import styled from "styled-components";

export interface BoModalProps {
  onClose?: () => void;
  open: boolean;
  overflow?: string;
  position?: BoModalPositions;
  shouldCloseOnOverlayClick?: boolean;
  size?: keyof typeof MODAL_WIDTHS;
}

const MODAL_WIDTHS = {
  default: "500px",
  large: "1162px",
  medium: "800px",
};

const BoModalContentContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: start;
  position: relative;
  & > * {
    width: 100%;
  }
`;

const CloseButtonWrapper = styled.span`
  color: ${darkPurple["500"]};
  cursor: pointer;
  position: absolute;
  right: ${rhOpsSpacingPx(3)};
  top: ${rhOpsSpacingPx(3)};
  width: inherit;
`;

const modalStyle = (
  size: keyof typeof MODAL_WIDTHS,
  overflow: string,
  overrideContent: Record<string, string> = {}
) => {
  return {
    content: {
      backgroundColor: trueWhite,
      border: borders["100"],
      borderRadius: "6px",
      bottom: "initial",
      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
      height: "auto",
      left: 0,
      margin: "5% auto",
      overflow: `${overflow}`,
      padding: 0,
      position: "relative",
      top: 0,
      width: `${MODAL_WIDTHS[size]}`,
      zIndex: 1300,
      ...overrideContent,
    } as React.CSSProperties,
    overlay: {
      ...defaultStyles.overlay,
      backgroundColor: "rgba(69, 65, 76, 0.65)" /* grey 700 with 65% opacity */,
      fontFamily: "'Averta', Helvetica, Arial, sans-serif",
      height: "100%",
      left: 0,
      overflow: "auto",
      top: 60,
      width: "100%",
      zIndex: 1250,
    },
  };
};

const ModalSafeForReact18 = ReactModal as ComponentType<ReactModal["props"]>;

export const BoModal: FC<React.PropsWithChildren<BoModalProps>> = ({
  open,
  onClose = () => {},
  size = "default",
  children,
  overflow = "auto",
  position,
  shouldCloseOnOverlayClick = true,
}) => {
  let overrideModalContent: Record<string, string> = {};

  if (position === BoModalPositions.Fixed) {
    overrideModalContent = {
      bottom: "96px",
      margin: "0 auto",
      position: "fixed",
      top: "96px",
    };
  }

  return (
    <ModalSafeForReact18
      style={modalStyle(size, overflow, overrideModalContent)}
      isOpen={open}
      onRequestClose={onClose}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      ariaHideApp={false}
      onAfterOpen={() => {
        document.body.style.height = "110vh";
        document.body.style.overflowY = "hidden";
      }}
      onAfterClose={() => {
        document.body.style.height = "";
        document.body.style.overflowY = "";
      }}
    >
      <BoModalContentContainer>
        <CloseButtonWrapper onClick={onClose}>
          <XIcon />
        </CloseButtonWrapper>
        {children}
      </BoModalContentContainer>
    </ModalSafeForReact18>
  );
};
