import { ajax } from "@common/services/ajax";
import { IdType, UUIDType } from "@common/types/apiTypes";
import {
  CommercialProspectType,
  UpdateCommercialProspectRequestType,
} from "@ops-utils/types/commercialProspectTypes";

const BASE_PATH = "/api/rhapsody/commercial-prospect";

export const rhapsodyCommercialProspectApi = {
  create: () => {
    return ajax.post<CommercialProspectType>(`${BASE_PATH}/`, {});
  },
  retrieve: (id: IdType) => {
    return ajax.get<CommercialProspectType>(`${BASE_PATH}/${id}`);
  },
  update: (uuid: UUIDType, body: UpdateCommercialProspectRequestType) =>
    ajax.patch(`${BASE_PATH}/${uuid}`, body),
};
