import { IdType } from "@common/types/apiTypes";
import { ProspectSearchRequestType } from "@ops-utils/types/prospectTypes";

export const CommercialProspectQueryKeys = {
  BASE: ["commercial-prospect"],
  retrieve: (id: IdType) => [...CommercialProspectQueryKeys.BASE, id],
};

export const AcquisitionCampaignQueryKeys = {
  BASE: ["acquisition-campaign"],
  list: () => [...AcquisitionCampaignQueryKeys.BASE, "list"],
};

export const BrandQueryKeys = {
  BASE: ["brand"],
  list: () => [...BrandQueryKeys.BASE, "list"],
};

export const ProspectQueryKeys = {
  BASE: ["prospect"],
  availableOfferSnapshots: (id: IdType) => [
    ...ProspectQueryKeys.retrieve(id),
    "available-offer-snapshots",
  ],
  list: (options: ProspectSearchRequestType) => [
    ...ProspectQueryKeys.BASE,
    options,
  ],
  retrieve: (id: IdType) => [...ProspectQueryKeys.BASE, id],
};
