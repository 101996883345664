import { rhapsodyBrandApi } from "@ops-data/api/rhapsodyBrandApi";
import { BrandQueryKeys } from "@ops-data/constants/queryKeys.constants";
import { useQuery } from "@tanstack/react-query";

interface BrandsQueryProps {
  queryOptions?: {
    enabled?: boolean;
  };
}

export const useBrandsQuery = (props: BrandsQueryProps) => {
  const { queryOptions: { enabled = true } = {} } = props;

  return useQuery({
    enabled,
    queryFn: () => rhapsodyBrandApi.list(),
    queryKey: BrandQueryKeys.list(),
  });
};
