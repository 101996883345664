import { rhapsodyProspectApi } from "@ops-data/api/rhapsodyProspectApi";
import { ProspectQueryKeys } from "@ops-data/constants/queryKeys.constants";
import { ProspectSearchRequestType } from "@ops-utils/types/prospectTypes";
import { useQuery } from "@tanstack/react-query";

interface UseProspectListQueryProps {
  queryOptions: {
    enabled?: boolean;
  };
  searchOptions: ProspectSearchRequestType;
}

export const useProspectListQuery = (props: UseProspectListQueryProps) => {
  const { searchOptions, queryOptions: { enabled = true } = {} } = props;

  return useQuery({
    enabled,
    queryFn: () => rhapsodyProspectApi.search(searchOptions),
    queryKey: ProspectQueryKeys.list(searchOptions),
  });
};
