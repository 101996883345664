import { AddressForm } from "@common/models/AddressForm.model";
import { IdType, UUIDType } from "@common/types/apiTypes";
import {
  CreditScoreEvaluationType,
  CreditScoreOutcome,
} from "@common/types/creditCheckTypes";
import { EnrollmentType } from "@common/types/customerTypes";
import {
  formatMonthDayYear,
  formatPhoneNumber,
} from "@common/utils/dataFormatters";
import { identity } from "@common/utils/genericFunctions";
import { OpsLocale } from "@ops-utils/types/LocaleTypes";
import {
  ProspectMeterType,
  ProspectType,
} from "@ops-utils/types/prospectTypes";
import isEmpty from "lodash/isEmpty";

export class Prospect {
  public readonly id: string;
  public readonly uuid: string;
  public readonly createdAt: string;
  public readonly updatedAt?: string;
  public email: string | null;
  public firstName: string | null;
  public lastName: string | null;
  public phone: string | null;
  public dateOfBirth: string | null;

  public readonly googleClientId: string | null;
  public readonly segmentAnonId: string | null;

  public autopay: boolean | null;
  public billingPaymentMethodId?: string | null;
  public enrollmentType?: EnrollmentType | null;
  public eBillOnly: boolean | null;
  public serviceStartDate: string | null;
  public isPaperless: boolean;

  public marketingEmailOptIn: boolean | null;
  public areaNotCovered: boolean | null;
  public estimatedUsageKilowattHours: number | null;

  // Address Form
  public serviceAddress: AddressForm;

  public creditCheckSummary?: CreditScoreEvaluationType | null;

  // Attribution props
  acquisitionMedium: string | null;
  acquisitionSource: string | null;
  acquisitionCampaign: string | null;
  acquisitionContent: string | null;

  public bypassedCreditCheck: boolean;
  public governmentIdRequested: boolean;
  public nosRequested: boolean;

  public esiId: string | null;
  public referralCode: string | null;

  public productPrices: UUIDType[] | null;
  private readonly meter: ProspectMeterType | null;
  public readonly offersnapshotId: IdType | null;

  public readonly languagePreference: OpsLocale | null;

  public depositAlternativeElected: boolean;

  public brand: IdType | null;

  public constructor(prospect: ProspectType) {
    this.id = prospect.id;
    this.uuid = prospect.uuid;
    this.createdAt = prospect.createdAt;
    this.updatedAt = prospect.updatedAt;
    this.email = prospect.email;
    this.firstName = prospect.firstName;
    this.lastName = prospect.lastName;
    this.phone = prospect.phone;
    this.dateOfBirth = prospect.dateOfBirth;

    this.googleClientId = prospect.googleClientId;
    this.segmentAnonId = prospect.segmentAnonId;

    this.autopay = prospect.autopay;
    this.billingPaymentMethodId = prospect.billingPaymentMethodId;
    this.enrollmentType = prospect.enrollmentType;
    this.eBillOnly = prospect.eBillOnly;
    this.serviceStartDate = prospect.serviceStartDate;
    this.isPaperless = prospect.isPaperless;

    this.marketingEmailOptIn = prospect.marketingEmailOptIn;
    this.areaNotCovered = prospect.areaNotCovered;
    this.estimatedUsageKilowattHours = prospect.estimatedUsageKilowattHours;

    this.serviceAddress = new AddressForm({
      addressLine1: prospect.addressLine1,
      city: prospect.city,
      state: prospect.state,
      unitNumber: prospect.unitNumber,
      zipCode: prospect.zipCode,
    });

    this.esiId = prospect.meterId;
    this.acquisitionMedium = prospect.acquisitionMedium;
    this.acquisitionSource = prospect.acquisitionSource;
    this.acquisitionCampaign = prospect.acquisitionCampaign;
    this.acquisitionContent = prospect.acquisitionContent;

    this.languagePreference = prospect.languagePreference;
    this.referralCode = prospect.referralCode;

    this.bypassedCreditCheck = prospect.bypassedCreditCheck;
    this.governmentIdRequested = prospect.governmentIdRequested;
    this.nosRequested = prospect.nosRequested;

    this.creditCheckSummary = prospect.creditCheckSummary;
    this.meter = prospect.meter;

    this.offersnapshotId = prospect.offersnapshotId;

    this.productPrices = prospect.productPrices ?? null;
    this.depositAlternativeElected = prospect.depositAlternativeElected;

    this.brand = prospect.brand;
  }

  get premiseType(): string | null {
    return this.meter?.premiseType ?? null;
  }

  get utilityName(): string | null {
    return this.meter?.utilityName ?? null;
  }

  get hasCreditCheck(): boolean {
    return (
      Boolean(this.creditCheckSummary) &&
      !isEmpty(this.creditCheckSummary) &&
      this.creditCheckSummary?.outcome !== CreditScoreOutcome.unavailable
    );
  }

  get depositAmount(): number | null {
    return this.creditCheckSummary?.depositAmount ?? null;
  }

  get depositAlternativeAmount(): string | null {
    return this.creditCheckSummary?.depositAlternativeAmount ?? null;
  }

  get ssnRequired(): boolean {
    return this.creditCheckSummary?.ssnRequired ?? false;
  }

  get ssnProvided(): boolean {
    return this.creditCheckSummary?.ssnProvided ?? false;
  }

  get requiresDeposit(): boolean {
    return (this.creditCheckSummary?.depositAmount ?? 0) > 0;
  }

  get needsManualReview(): boolean {
    return this.creditCheckSummary?.outcome === CreditScoreOutcome.manualReview;
  }

  get nosRequired(): boolean {
    // Get if new occupant statement required
    return this.creditCheckSummary?.nosRequired ?? false;
  }

  get additionalInformationRequired(): boolean {
    return this.creditCheckSummary?.additionalInformationRequired ?? false;
  }

  get createdAtDate() {
    return formatMonthDayYear(this.createdAt);
  }

  get formattedPhoneNumber() {
    return this.phone ? formatPhoneNumber(this.phone) : "";
  }

  get fullName() {
    return [this.firstName, this.lastName].filter(identity).join(" ");
  }
}
