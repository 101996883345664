import { IdType, UUIDType } from "@common/types/apiTypes";
import { rhapsodyCommercialProspectApi } from "@ops-data/api/rhapsodyCommercialProspectApi";
import { CommercialProspectQueryKeys } from "@ops-data/constants/queryKeys.constants";
import { UpdateCommercialProspectRequestType } from "@ops-utils/types/commercialProspectTypes";
import { useMutation, useQueryClient } from "@tanstack/react-query";

interface UseUpdateCommercialProspectMutationProps {
  body: UpdateCommercialProspectRequestType;
  id: IdType;
  uuid: UUIDType;
}

export const useUpdateCommercialProspectMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ uuid, body }: UseUpdateCommercialProspectMutationProps) =>
      rhapsodyCommercialProspectApi.update(uuid, body),
    onSuccess: (_, { id }) =>
      queryClient.invalidateQueries({
        queryKey: CommercialProspectQueryKeys.retrieve(id),
      }),
  });
};
