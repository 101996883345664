import { MONTH_DAY_YEAR_SLASH } from "@common/constants/date.constant";
import { AppBrands } from "@common/enums/appBrands.enum";
import { AcquisitionCampaignType } from "@common/types/campaignTypes";
import dayjs, { Dayjs } from "dayjs";

export class AcquisitionCampaign {
  public readonly id: string;
  public readonly slug: string;
  public readonly name: string;
  public readonly phoneNumber: string;
  public readonly pricingCampaignSlug: string;
  public readonly active: boolean;
  public readonly salesChannel: string;
  public readonly brand: AppBrands;

  private readonly createdAt: Dayjs;

  constructor(acquisitionCampaign: AcquisitionCampaignType) {
    this.slug = acquisitionCampaign.slug;
    this.name = acquisitionCampaign.name;
    this.phoneNumber = acquisitionCampaign.phone;
    this.pricingCampaignSlug = acquisitionCampaign.pricingCampaignSlug;
    this.id = acquisitionCampaign.id;
    this.createdAt = dayjs(acquisitionCampaign.createdAt);
    this.active = acquisitionCampaign.active;
    this.salesChannel = acquisitionCampaign.salesChannel;
    this.brand = acquisitionCampaign.brand;
  }

  get status() {
    return this.active ? "Active" : "Inactive";
  }

  get createdDate() {
    return this.createdAt.format(MONTH_DAY_YEAR_SLASH);
  }
}
