import { contractRenewalWizardStepMapping } from "@ops/components/ContractRenewalWizard/ContractRenewalWizard.constants";
import { ContractRenewalWizardFormStateType } from "@ops/components/ContractRenewalWizard/ContractRenewalWizard.schemas";
import { atomWithReset } from "jotai/utils";

export const contractRenewalWizardModalAtom = atomWithReset(false);

export const contractRenewalWizardCurrentStepAtom =
  atomWithReset<keyof typeof contractRenewalWizardStepMapping>("offersStep");

export const contractRenewalWizardStateAtom = atomWithReset<
  Omit<ContractRenewalWizardFormStateType, "paperless"> & {
    paperless: undefined | boolean;
  }
>({
  confirmRenewal: false,
  offerSnapshotId: "",
  paperless: undefined,
});
