import { OpsSelect } from "@ops-design-system/components/OpsSelect/OpsSelect";
import { Subtitle2 } from "@ops-design-system/components/Typography/Typography";
import { FontWeight } from "@ops-design-system/styles/FontWeight.enum";
import { rhOpsSpacingPx } from "@ops-design-system/utils/styleHelpers";
import styled from "styled-components";

export const SearchHeader = styled(Subtitle2).attrs({ as: "div" })`
  font-weight: ${FontWeight.SemiBold};
  margin-bottom: ${rhOpsSpacingPx(1.5)};
  margin-top: ${rhOpsSpacingPx(3)};
`;

export const SearchFormRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${rhOpsSpacingPx(3)} ${rhOpsSpacingPx(2)};

  & > * {
    flex: 0 0 240px;
  }
`;

export const FormButtonRow = styled.div`
  align-items: center;
  display: flex;
  gap: ${rhOpsSpacingPx(2)};
  margin-top: 16px;
`;

export const StyledOpsSelect = styled(OpsSelect)`
  margin: 0;
  margin-top: 6px;
  padding: 8px;
`;
